<template>
  <v-app id="app">
    <div id="nav">
      <router-link to="/"></router-link>
    </div>
    <router-view/>
  </v-app>
</template>
<script>
export default {
  methods: {
    goto (path) {
      this.$router.push({name: path})
    }
  },
  props: {
    source: String,
  },
  data: () => ({
    mini: true,
  }),
  created () {
    this.$vuetify.theme.dark = false
  },
}
</script>
<style lang="scss">
.example::-webkit-scrollbar {
  display: none;
}
#app {
  font-family: 'Montserrat', sans-serif;
  overflow: hidden;
  // font-family: 'Prompt', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
