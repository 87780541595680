<template>
    <v-layout wrap>
        <v-flex xs12>
            <Nav></Nav>
            <Section1></Section1>
            <Section2></Section2>
            <Section3></Section3>
            <Section5></Section5>
        </v-flex>
    </v-layout>
</template>     
<script>
import Section1 from '@/components/services/section1'
import Nav from '@/components/navigation'
import Section2 from '@/components/services/section2';
import Section3 from '@/components/services/section3';
import Section5 from '@/components/homepage/section5';
export default {
    components: { Nav, Section1, Section2, Section3, Section5}
    
}
</script>