<template>
    <v-layout wrap>
        <v-flex xs12>
            <Nav></Nav>
            <Section4></Section4>
        </v-flex>
    </v-layout>
</template>
<script>
import Nav from '@/components/navigation'
import Section4 from '@/components/meetbrains/section1';
export default {
    components: { Nav, Section4}
    
}
</script>