<template>
    <div style="">
        <v-row no-gutters style="background-color:black;" v-show="this.$vuetify.breakpoint.mdAndUp">
            <v-col cols="12" style="">
                <v-img
                    style=""
                    :min-height="minHeight"
                    :src="require('@/assets/pencil.png')"
                    class="white--text"
                    >                        
                        <v-row no-gutters style="margin-top:5%;">
                            <v-col cols="1"></v-col>
                            <v-col cols="3">
                                <div class="text-left" style="font-size:59px; color:#F3DFAD;">
                                    <p class="ma-0">CREATE</p>
                                    <p class="mt-n4">INSPIRE</p>
                                    <p class="mt-n8">BELIEVE</p>
                                    <p style="font-size:20px; font-weight:300; ">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Accumsan sit amet nulla facilisi morbi tempus. Non consectetur a erat nam at lectus urna duis. Phasellus egestas tellus rutrum tellus. Vitae ultricies leo integer malesuada. Tellus in hac habitasse platea dictumst vestibulum. Id faucibus nisl tincidunt eget nullam non.</p>
                                </div>
                            </v-col>
                        </v-row>
                    </v-img>
            </v-col>
        </v-row>
        <v-layout wrap v-show="this.$vuetify.breakpoint.smAndDown">
            <v-flex xs12 >
                <div :style="bgprops">
                    <v-layout wrap justify-center>
                      <v-flex xs12>
                            <img width="100%" style="margin-top:-14%;" src="https://firebasestorage.googleapis.com/v0/b/brainandbrawn-ea849.appspot.com/o/artwork1-01.png?alt=media&token=e8d53d30-70ad-4733-a741-ee0be4fb260e" alt="">
                        </v-flex>
                        <v-flex xs10>
                            <div class="text-center" style="padding-top:30px;">
                                <img  src="@/assets/Logomark.svg" alt="">
                            </div>
                        </v-flex>
                        <v-flex xs12>
                            <img width="100%" style="margin-top:-14%;" src="https://firebasestorage.googleapis.com/v0/b/brainandbrawn-ea849.appspot.com/o/artwork1-01.png?alt=media&token=e8d53d30-70ad-4733-a741-ee0be4fb260e" alt="">
                        </v-flex>
                    </v-layout>
                </div>
            </v-flex>
        </v-layout>
    </div>
</template>
<script>
export default {
    data () {
      return {
          bgprops: {
            backgroundColor: 'black',
            backgroundImage: `url(https://firebasestorage.googleapis.com/v0/b/brainandbrawn-ea849.appspot.com/o/artwork1-01.png?alt=media&token=e8d53d30-70ad-4733-a741-ee0be4fb260e)`,
            minHeight: '100vh',
            maxHeight: '100%'
          }
      }
    }
}
</script>
<style >

#myVideo {
  position: relative;
  right: 0;
  bottom: 0;
  min-width: 100%; 
  min-height: 100%;
}
.content {
  position: fixed;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  color: #f1f1f1;
  width: 100%;
  padding: 20px;
}
/* #myVideo {
  -moz-transform:scale(1.5);
  -webkit-transform:scale(1.5);
  -o-transform:scale(1.5);
  -ms-transform:scale(1.5);
  transform:scale(1.5);
  position: fixed;
  right: 0;
  bottom: 0;
  
  min-width: 100%;
  min-height: 100%;
  background-position: center top; 
} */
.content {
  position: fixed;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  color: #f1f1f1;
  width: 100%;
  padding: 20px;
}
    
</style>