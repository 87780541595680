import firebase from 'firebase'
var config = {
    apiKey: "AIzaSyDljQSKBEmXsco5PDlhLucG3eqk5smS6os",
    authDomain: "brainandbrawn-ea849.firebaseapp.com",
    databaseURL: "https://brainandbrawn-ea849.firebaseio.com",
    projectId: "brainandbrawn-ea849",
    storageBucket: "brainandbrawn-ea849.appspot.com",
    messagingSenderId: "342860564967",
    appId: "1:342860564967:web:92d1b1f2e29a6e4d551542",
    measurementId: "G-2QPEMZ5GDJ"
}
firebase.initializeApp(config)
export default firebase

export const database = firebase.database()
export const firestore = firebase.firestore()
export const auth = firebase.auth()
export const storage = firebase.storage()
