<template>
    <v-main>
        <v-layout wrap>
            <v-flex xs12>
              <v-row no-gutters style="margin-left:0%; margin-right:0%; background-color:black; " v-show="this.$vuetify.breakpoint.mobile">
                 <!-- 1- 5 -->
                  <v-col cols="12" v-for="(x, index) in urls.slice(0, 5)" :key="index">
                        <div class="text-center" style="margin-left:0%; margin-right:0%;"  >
                            <v-img :src="x">

                            </v-img>
                        </div>
                  </v-col>
                  <!-- 6 but -->
                  <v-col cols="12" >
                      <div class="text-center" style="margin-left:0%; margin-right:0%;"  >
                          <v-img  :src="urls[5]">
                            <!-- <v-row no-gutters style="margin-top:125%;">
                              <v-col cols="6">
                                <div style="margin-left:5%;">
                                  <v-img style="cursor:pointer; width:45%;  margin-left:5%;" :src="require('@/assets/white.png')"></v-img>
                                </div>
                              </v-col>
                              <v-col cols="6">
                                <div style="margin-left:-6%; margin-top:60%;">
                                  <v-img style="cursor:pointer; width:40%;  margin-left:5%;" :src="require('@/assets/white.png')"></v-img>
                                </div>
                              </v-col>
                            </v-row> -->
                          </v-img>
                      </div>
                  </v-col>
                  <!-- 7 -->
                  <v-col cols="12" >
                      <div class="text-center" style="margin-left:0%; margin-right:0%;"  >
                          <v-img  :src="urls[6]">
                            <v-row no-gutters style="margin-top:105%;">
                              <v-col cols="6">
                                <div style="margin-left:0%;">
                                  <a target=""  :href="links[0]" style="color:white; text-decoration:none;">
                                      <span class="" style=" margin-left:-13%;"> <u style="font-weight:600; color:white;">BUY NOW</u> > </span> 
                                  </a>
                                </div>
                              </v-col>
                            </v-row>
                          </v-img>
                      </div>
                  </v-col>
                  <!-- 8 no but-->
                  <v-col cols="12" >
                      <div class="text-center" style="margin-left:0%; margin-right:0%;"  >
                          <v-img  :src="urls[7]">
                          </v-img>
                      </div>
                  </v-col>
                  <!-- 9 but -->
                  <v-col cols="12" >
                      <div class="text-center" style="margin-left:0%; margin-right:0%;"  >
                          <v-img  :src="urls[8]">
                            <v-row no-gutters style="margin-top:40%;">
                              <v-col cols="6">
                                <div style="margin-left:0%;">
                                   <a target="_blank"  :href="links[1]" style="color:white; text-decoration:none;">
                                      <span class="" style=" margin-left:-20%;"> <u style="font-weight:600; color:white;">BUY NOW</u> > </span> 
                                  </a>
                                </div>
                                <div style="margin-top:180%;">
                                  <a target="_blank"  :href="links[2]" style="color:white; text-decoration:none;">
                                      <span class="" style=" margin-left:-20%;"> <u style="font-weight:600; color:white;">BUY NOW</u> > </span> 
                                  </a>
                                </div>
                              </v-col>
                            </v-row>
                          </v-img>
                      </div>
                  </v-col>
                  <!-- 10 but -->
                  <v-col cols="12" >
                      <div class="text-center" style="margin-left:0%; margin-right:0%;"  >
                          <v-img  :src="urls[9]">
                            <v-row no-gutters style="margin-top:35%;">
                              <v-col cols="4"></v-col>
                              <v-col cols="8">
                                <div style="margin-left:24%; margin-top:5%;">
                                  <a target="_blank"  :href="links[3]" style="color:white; text-decoration:none;">
                                      <span class="" style=" margin-left:-50%;"> <u style="font-weight:600; color:white;">BUY NOW</u> > </span> 
                                  </a>
                                </div>
                                <div style="margin-top:95%; margin-left:1%; margin-top:125%;" class="text-left">
                                  <a target="_blank"  :href="links[4]" style="color:white; text-decoration:none;">
                                      <span class="" style=" margin-left:0%;"> <u style="font-weight:600; color:white;">BUY NOW</u> > </span> 
                                  </a>
                                </div>
                              </v-col>
                            </v-row>
                          </v-img>
                      </div>
                  </v-col>
                  <!-- 11-12 no but -->
                  <v-col cols="12" v-for="(x, index) in urls.slice(10, 12)" :key="index">
                        <div class="text-center" style="margin-left:0%; margin-right:0%;"  >
                            <v-img :src="x">
                            </v-img>
                        </div>
                  </v-col>
                  <!-- 13 but -->
                  <v-col cols="12" >
                      <div class="text-center" style="margin-left:0%; margin-right:0%;"  >
                          <v-img  :src="urls[12]">
                            <v-row no-gutters style="margin-top:117%;">
                              <v-col cols="5"></v-col>
                              <v-col cols="7">
                                <div style="margin-left:15%;" >
                                  <a target="_blank"  :href="links[5]" style="color:black; text-decoration:none;">
                                      <span class="" style=" margin-left:-50%;"> <u style="font-weight:600; color:black;">BUY NOW</u> > </span> 
                                  </a>

                               </div>
                              </v-col>
                            </v-row>
                          </v-img>
                      </div>
                  </v-col>
                  <!-- 14 but -->
                  <v-col cols="12" >
                      <div class="text-center" style="margin-left:0%; margin-right:0%;"  >
                          <v-img  :src="urls[13]">
                            <v-row no-gutters style="margin-top:120%;">
                              <v-col cols="7"></v-col>
                              <v-col cols="5">
                                <div style="margin-left:16%;">
                                  <a target="_blank"  :href="links[6]" style="color:black; text-decoration:none;">
                                      <span class="" style=" margin-left:-26%;"> <u style="font-weight:600; color:black;">BUY NOW</u> > </span> 
                                  </a>
                                </div>
                              </v-col>
                            </v-row>
                          </v-img>
                      </div>
                  </v-col>
                  <!-- 15 but -->
                  <v-col cols="12" >
                      <div class="text-center" style="margin-left:0%; margin-right:0%;"  >
                          <v-img  :src="urls[14]">
                            <v-row no-gutters style="margin-top:71%;">
                              <v-col cols="12">
                                <div style="margin-left:5%; ">
                                  <a target="_blank"  :href="links[7]" style="color:black; text-decoration:none;">
                                      <span class="" style=" margin-left:-70%;"> <u style="font-weight:600; color:black;">BUY NOW</u> > </span> 
                                  </a>
                                </div>
                              </v-col>
                            </v-row>
                          </v-img>
                      </div>
                  </v-col>
                  <!-- 16 no but -->
                  <v-col cols="12" >
                      <div class="text-center" style="margin-left:0%; margin-right:0%;"  >
                          <v-img  :src="urls[15]">
                          </v-img>
                      </div>
                  </v-col>
                  <!-- 17 but -->
                  <v-col cols="12" >
                      <div class="text-center" style="margin-left:0%; margin-right:0%;"  >
                          <v-img  :src="urls[16]">
                            <v-row no-gutters style="margin-top:9%;">
                              <v-col cols="5">
                                <div style="margin-left:7%;">
                                  <a target="_blank"  :href="links[8]" style="color:black; text-decoration:none;">
                                      <span class="" style=" margin-left:-30%;"> <u style="font-weight:600; color:black;">BUY NOW</u> > </span> 
                                  </a>
                                </div>
                                
                              </v-col>                             
                              <v-col cols="6">
                                <div style="margin-left:0%; margin-top:170%;">
                                  <a target="_blank"  :href="links[9]" style="color:black; text-decoration:none;">
                                      <span class="" style=" margin-left:-30%;"> <u style="font-weight:600; color:black;">BUY NOW</u> > </span> 
                                  </a>
                                </div>
                              </v-col>
                              <v-col cols="12">
                                <div  style="margin-top:74%; margin-left:20%;">
                                  <a target="_blank"  :href="links[10]" style="color:black; text-decoration:none;">
                                      <span class="" style=" margin-left:-65%;"> <u style="font-weight:600; color:black;">BUY NOW</u> > </span> 
                                  </a>
                                </div>
                              </v-col>
                            </v-row>
                          </v-img>
                      </div>
                  </v-col>
                  <!-- 18 but -->
                  <v-col cols="12" >
                      <div class="text-center" style="margin-left:0%; margin-right:0%;"  >
                          <v-img  :src="urls[17]">
                            <v-row no-gutters style="margin-top:160%;">
                              <v-col cols="8"></v-col>
                              <v-col cols="4">
                                <div style="margin-left:0%; margin-top:0%; text-left">
                                  <a target="_blank"  :href="links[11]" style="color:white; text-decoration:none;">
                                      <span class="" style=" margin-left:-20%;"> <u style="font-weight:600; color:white;">BUY NOW</u> > </span> 
                                  </a>
                                </div>
                              </v-col>
                            </v-row>
                          </v-img>
                      </div>
                  </v-col>
                  <!-- 19 but -->
                  <v-col cols="12" >
                      <div class="text-center" style="margin-left:0%; margin-right:0%;"  >
                          <v-img  :src="urls[18]">
                            <v-row no-gutters style="margin-top:100%;">
                              <v-col cols="12">
                                <div class="text-left" style="margin-left:11%; margin-top:0%; text-left">
                                  <a target="_blank"  :href="links[12]" style="color:white; text-decoration:none;">
                                      <span class="" style=" margin-left:-0%;"> <u style="font-weight:600; color:white;">BUY NOW</u> > </span> 
                                  </a>
                                </div>
                              </v-col>
                            </v-row>
                          </v-img>
                      </div>
                  </v-col>
                  <!-- 20 no but -->
                  <v-col cols="12" >
                      <div class="text-center" style="margin-left:0%; margin-right:0%;"  >
                          <v-img  :src="urls[19]">
                          </v-img>
                      </div>
                  </v-col>
                  <!-- 21 but -->
                  <v-col cols="12" >
                      <div class="text-center" style="margin-left:0%; margin-right:0%;"  >
                          <v-img  :src="urls[20]">
                            <v-row no-gutters style="margin-top:160%;">
                              <v-col cols="12">
                                <div class="text-left" style="margin-left:9%; margin-top:0%; text-left">
                                  <a target="_blank"  :href="links[13]" style="color:black; font-size:25px; text-decoration:none;">
                                      <span class="" style=" margin-left:0%;"> <u style="font-weight:600; color:black;">BUY NOW</u> > </span> 
                                  </a>
                                </div>
                              </v-col>
                            </v-row>
                          </v-img>
                      </div>
                  </v-col>
                  <!-- 22 no but -->
                  <v-col cols="12" >
                      <div class="text-center" style="margin-left:0%; margin-right:0%;"  >
                          <v-img  :src="urls[21]">
                          </v-img>
                      </div>
                  </v-col>
                  <!-- 23 but -->
                  <v-col cols="12" >
                      <div class="text-center" style="margin-left:0%; margin-right:0%;"  >
                          <v-img  :src="urls[22]">
                            <v-row no-gutters style="margin-top:60%;">
                              <v-col cols="12">
                                <div style="margin-left:35%; margin-top:0%;" class="text-right">
                                  <a target="_blank"  :href="links[14]" style="color:black; font-size:15  px; text-decoration:none;">
                                      <span class="" style=" margin-right:4%;"> <u style="font-weight:600; color:black;">BUY NOW</u> > </span> 
                                  </a>
                                </div>
                              </v-col>
                              <v-col cols="12">
                                <div class="text-left" style="margin-left:4%; margin-top:90%; text-left">
                                  <a target="_blank"  :href="links[15]" style="color:black; text-decoration:none;">
                                      <span class="" style=" margin-left:0%;"> <u style="font-weight:600; color:black;">BUY NOW</u> > </span> 
                                  </a>
                                </div>
                              </v-col>
                            </v-row>
                          </v-img>
                      </div>
                  </v-col>
                  <!-- 24 but -->
                  <v-col cols="12" >
                      <div class="text-center" style="margin-left:0%; margin-right:0%;"  >
                          <v-img  :src="urls[23]">
                            <v-row no-gutters style="margin-top:65%;">
                              <v-col cols="6"></v-col>
                              <v-col cols="6">
                                <div style="margin-left:0%; margin-top:3%;">
                                  <a target="_blank"  :href="links[16]" style="color:black; text-decoration:none;">
                                      <span class="" style=" margin-left:-20%;"> <u style="font-weight:600; color:black;">BUY NOW</u> > </span> 
                                  </a>
                                </div>
                              </v-col>
                            </v-row>
                          </v-img>
                      </div>
                  </v-col>
                  <!-- 25 but -->
                  <v-col cols="12" >
                      <div class="text-center" style="margin-left:0%; margin-right:0%;"  >
                          <v-img  :src="urls[24]">
                            <v-row no-gutters style="margin-top:75%;">
                              <v-col cols="6"></v-col>
                              <v-col cols="5">
                                <div style="margin-left:19%; margin-top:0%; " class="text-center">
                                  <a target="_blank"  :href="links[17]" style="color:black; text-decoration:none;">
                                      <span class="" style=" margin-left:-25%;"> <u style="font-weight:600; color:black;">BUY NOW</u> > </span> 
                                  </a>
                                </div>
                              </v-col>
                            </v-row>
                          </v-img>
                      </div>
                  </v-col>
                  <!-- 26 but -->
                  <v-col cols="12" >
                      <div class="text-center" style="margin-left:0%; margin-right:0%;"  >
                          <v-img  :src="urls[25]">
                            <v-row no-gutters style="margin-top:45%;">
                              <v-col cols="8"></v-col>
                              <v-col cols="4">
                                <div class="text-left" style="margin-left:0%; margin-top:0%;">
                                  <a target="_blank"  :href="links[18]" style="color:black; font-size:13px; text-decoration:none;">
                                      <span class="" style=" margin-left:20%;"> <u style="font-weight:600; color:black;">BUY NOW</u> > </span> 
                                  </a>
                                </div>
                              </v-col>
                            </v-row>
                          </v-img>
                      </div>
                  </v-col>
                  <!-- 27 but -->
                  <v-col cols="12" >
                      <div class="text-center" style="margin-left:0%; margin-right:0%;"  >
                          <v-img  :src="urls[26]">
                            <v-row no-gutters style="margin-top:55%;">
                              <v-col cols="8"></v-col>
                              <v-col cols="4">
                                <div style="margin-left:15%; margin-top:0%;" class="text-center">
                                  <a target="_blank"  :href="links[19]" style="color:white; text-decoration:none;">
                                      <span class="" style=" margin-left:-20%;"> <u style="font-weight:600; color:white;">BUY NOW</u> > </span> 
                                  </a>
                                </div>
                              </v-col>
                            </v-row>
                          </v-img>
                      </div>
                  </v-col>
                  <!-- 28 no but -->
                  <v-col cols="12" >
                      <div class="text-center" style="margin-left:0%; margin-right:0%;"  >
                          <v-img  :src="urls[27]">
                          </v-img>
                      </div>
                  </v-col>
                  <!-- 29 but -->
                  <v-col cols="12" >
                      <div class="text-center" style="margin-left:0%; margin-right:0%;"  >
                          <v-img  :src="urls[28]">
                            <v-row no-gutters style="margin-top:65%;">
                              <v-col cols="6">
                                <div style="margin-left:8%; margin-top:0%;" class="text-left">
                                  <a target="_blank"  :href="links[20]" style="color:black; text-decoration:none;">
                                      <span class="" style=" margin-left:0%;"> <u style="font-weight:600; color:black;">BUY NOW</u> > </span> 
                                  </a>
                                </div>
                              </v-col>
                              <v-col cols="6">
                                <div style="margin-left:15%; margin-top:200%; text-left">
                                  <a target="_blank"  :href="links[21]" style="color:black; text-decoration:none;">
                                      <span class="" style=" margin-left:-40%;"> <u style="font-weight:600; color:black;">BUY NOW</u> > </span> 
                                  </a>
                                </div>
                              </v-col>
                            </v-row>
                          </v-img>
                      </div>
                  </v-col>
                  <!-- 30 no but -->
                  <v-col cols="12" >
                      <div class="text-center" style="margin-left:0%; margin-right:0%;"  >
                          <v-img  :src="urls[29]">
                          </v-img>
                      </div>
                  </v-col>
                  <!-- 31 but -->
                  <v-col cols="12" >
                      <div class="text-center" style="margin-left:0%; margin-right:0%;"  >
                          <v-img  :src="urls[30]">
                            <v-row no-gutters style="margin-top:48%;">
                              <v-col cols="8">
                                <div class="text-left" style="margin-left:6%; margin-top:0%;">
                                  <a target="_blank"  :href="links[22]" style="color:white; text-decoration:none;">
                                      <span class="" style=" margin-left:0%;"> <u style="font-weight:600; color:white;">BUY NOW</u> > </span> 
                                  </a>
                                </div>
                              </v-col>
                              <v-col cols="4">
                                <div style="margin-left:15%; margin-top:343%;" class="text-center">
                                  <a target="_blank"  :href="links[23]" style="color:white; text-decoration:none;">
                                      <span class="" style=" margin-left:0%;"> <u style="font-weight:600; color:white;">BUY NOW</u> > </span> 
                                  </a>
                                </div>
                              </v-col>
                            </v-row>
                          </v-img>
                      </div>
                  </v-col>
                  <!-- 32 but -->
                  <v-col cols="12" >
                      <div class="text-center" style="margin-left:0%; margin-right:0%;"  >
                          <v-img  :src="urls[31]">
                            <v-row no-gutters style="margin-top:88%;">
                              <v-col cols="12">
                                <div class="text-left" style="margin-left:8%; margin-top:0%;">
                                  <a target="_blank"  :href="links[24]" style="color:white; text-decoration:none;">
                                      <span class="" style=" margin-left:0%;"> <u style="font-weight:600; color:white;">BUY NOW</u> > </span> 
                                  </a>
                                </div>
                              </v-col>
                            </v-row>
                          </v-img>
                      </div>
                  </v-col>
                  <!-- 33 but -->
                  <v-col cols="12" >
                      <div class="text-center" style="margin-left:0%; margin-right:0%;"  >
                          <v-img  :src="urls[32]">
                            <v-row no-gutters style="margin-top:47%;">
                              <v-col cols="12">
                                <div class="text-left" style="margin-left:10%; margin-top:0%; ">
                                  <a target="_blank"  :href="links[25]" style="color:black; text-decoration:none;">
                                      <span class="" style=" margin-left:1%;"> <u style="font-weight:600; color:black;">BUY NOW</u> > </span> 
                                  </a>

                                </div>
                              </v-col>
                            </v-row>
                          </v-img>
                      </div>
                  </v-col>
                  <!-- 34 but -->
                  <v-col cols="12" >
                      <div class="text-center" style="margin-left:0%; margin-right:0%;"  >
                          <v-img  :src="urls[33]">
                            <v-row no-gutters style="margin-top:115%;">
                              <v-col cols="8"></v-col>
                              <v-col cols="4">
                                <div style="margin-left:20%; margin-top:0%; text-left">
                                  <a target="_blank"  :href="links[26]" style="color:black; font-size:15px; text-decoration:none;">
                                      <span class="" style=" margin-left:-10%;"> <u style="font-weight:600; color:black;">BUY NOW</u> > </span> 
                                  </a>
                                </div>
                              </v-col>
                            </v-row>
                          </v-img>
                      </div>
                  </v-col>
                  <!-- 35 but -->
                  <v-col cols="12" >
                      <div class="text-center" style="margin-left:0%; margin-right:0%;"  >
                          <v-img  :src="urls[34]">
                            <v-row no-gutters style="margin-top:47%;">
                              <v-col cols="5">
                                <div class="text-left" style="margin-left:10%; margin-top:0%;">
                                  <a target="_blank"  :href="links[27]" style="color:white; text-decoration:none;">
                                      <span class="" style=" margin-left:0%;"> <u style="font-weight:600; color:white;">BUY NOW</u> > </span> 
                                  </a>
                                </div>
                              </v-col>
                            </v-row>
                          </v-img>
                      </div>
                  </v-col>
                  <!-- 36 but -->
                  <v-col cols="12" >
                      <div class="text-center" style="margin-left:0%; margin-right:0%;"  >
                          <v-img  :src="urls[35]">
                            <v-row no-gutters style="margin-top:8%;">
                              <v-col cols="7"></v-col>
                              <v-col cols="5">
                                <div style="margin-left:10%; margin-top:0%; text-left">
                                  <a target="_blank"  :href="links[28]" style="color:white; text-decoration:none;">
                                      <span class="" style=" margin-left:0%;"> <u style="font-weight:600; color:white;">BUY NOW</u> > </span> 
                                  </a>
                                </div>
                              </v-col>
                            </v-row>
                          </v-img>
                      </div>
                  </v-col>
                  <!-- 37 no but -->
                  <v-col cols="12" >
                      <div class="text-center" style="margin-left:0%; margin-right:0%;"  >
                          <v-img  :src="urls[36]">
                          </v-img>
                      </div>
                  </v-col>
                  <!-- 38 but -->
                  <v-col cols="12" >
                      <div class="text-center" style="margin-left:0%; margin-right:0%;"  >
                          <v-img  :src="urls[37]">
                            <v-row no-gutters style="margin-top:65%;">
                              <v-col cols="12">
                                <div class="text-left" style="margin-left:5%; margin-top:0%;">
                                  <a target="_blank"  :href="links[29]" style="color:white; text-decoration:none;">
                                      <span class="" style=" margin-left:0%;"> <u style="font-weight:600; color:white;">BUY NOW</u> > </span> 
                                  </a>
                                </div>
                              </v-col>
                            </v-row>
                          </v-img>
                      </div>
                  </v-col>   
                   <!--39-40  -->
                   <v-col cols="12" >
                        <div class="text-center" style="margin-left:0%; margin-right:0%;"  >
                            <v-img src="https://firebasestorage.googleapis.com/v0/b/brainandbrawn-ea849.appspot.com/o/heritage%2Fp.39.jpg?alt=media&token=231a13fc-1739-44fc-bb31-c406812499af">

                            </v-img>
                        </div>
                  </v-col>
                  <v-col cols="12">
                        <div class="text-center" style="margin-left:0%; margin-right:0%;"  >
                            <v-img :src="urls[39]">

                            </v-img>
                        </div>
                  </v-col>
              </v-row>
               <v-row no-gutters style="margin-left:0%; margin-right:0%; background-color:black;" v-show="!this.$vuetify.breakpoint.mobile">
                 <!-- 1- 5 -->
                  <v-col cols="6" v-for="(x, index) in urls.slice(0, 5)" :key="index">
                        <div class="text-center" style="margin-left:0%; margin-right:0%;"  >
                            <v-img :src="x">

                            </v-img>
                        </div>
                  </v-col>
                  <!-- 6 but -->
                  <v-col cols="6" >
                      <div class="text-center" style="margin-left:0%; margin-right:0%;"  >
                          <v-img  :src="urls[5]">
                            <!-- <v-row no-gutters style="margin-top:125%;">
                              <v-col cols="6">
                                <div style="margin-left:5%;">
                                  <v-img style="cursor:pointer; width:45%;  margin-left:5%;" :src="require('@/assets/white.png')"></v-img>
                                </div>
                              </v-col>
                              <v-col cols="6">
                                <div style="margin-left:-6%; margin-top:60%;">
                                  <v-img style="cursor:pointer; width:40%;  margin-left:5%;" :src="require('@/assets/white.png')"></v-img>
                                </div>
                              </v-col>
                            </v-row> -->
                          </v-img>
                      </div>
                  </v-col>
                  <!-- 7 -->
                  <v-col cols="6" >
                      <div class="text-center" style="margin-left:0%; margin-right:0%;"  >
                          <v-img  :src="urls[6]">
                            <v-row no-gutters style="margin-top:105%;">
                              <v-col cols="6">
                                <div style="margin-left:0%;">
                                  <a target="_blank"  :href="links[0]">
                                  <v-img style="cursor:pointer; width:50%;  margin-left:13%;" :src="require('@/assets/white.png')"></v-img>
                                  </a>
                                </div>
                              </v-col>
                            </v-row>
                          </v-img>
                      </div>
                  </v-col>
                  <!-- 8 no but-->
                  <v-col cols="6" >
                      <div class="text-center" style="margin-left:0%; margin-right:0%;"  >
                          <v-img  :src="urls[7]">
                          </v-img>
                      </div>
                  </v-col>
                  <!-- 9 but -->
                  <v-col cols="6" >
                      <div class="text-center" style="margin-left:0%; margin-right:0%;"  >
                          <v-img  :src="urls[8]">
                            <v-row no-gutters style="margin-top:40%;">
                              <v-col cols="6">
                                <div style="margin-left:0%;">
                                  <a target="_blank"  :href="links[1]">
                                  <v-img style="cursor:pointer; width:45%;  margin-left:8%;" :src="require('@/assets/white.png')"></v-img>
                                  </a>
                                </div>
                                <div style="margin-top:180%;">
                                  <a target="_blank"  :href="links[2]">
                                  <v-img style="cursor:pointer; width:45%;  margin-left:7%;" :src="require('@/assets/white.png')"></v-img>
                                  </a>
                                </div>
                              </v-col>
                            </v-row>
                          </v-img>
                      </div>
                  </v-col>
                  <!-- 10 but -->
                  <v-col cols="6" >
                      <div class="text-center" style="margin-left:0%; margin-right:0%;"  >
                          <v-img  :src="urls[9]">
                            <v-row no-gutters style="margin-top:55%;">
                              <v-col cols="4"></v-col>
                              <v-col cols="8">
                                <div style="margin-left:25%;">
                                  <a target="_blank"  :href="links[3]">
                                  <v-img style="cursor:pointer; width:44%;" :src="require('@/assets/white.png')"></v-img>
                                  </a>
                                </div>
                                <div style="margin-top:95%; margin-left:1%;" class="text-left">
                                  <a target="_blank"  :href="links[4]">
                                  <v-img style="cursor:pointer; width:35%;  " :src="require('@/assets/white.png')"></v-img>
                                  </a>
                                </div>
                              </v-col>
                            </v-row>
                          </v-img>
                      </div>
                  </v-col>
                  <!-- 11-12 no but -->
                  <v-col cols="6" v-for="(x, index) in urls.slice(10, 12)" :key="index">
                        <div class="text-center" style="margin-left:0%; margin-right:0%;"  >
                            <v-img :src="x">
                            </v-img>
                        </div>
                  </v-col>
                  <!-- 13 but -->
                  <v-col cols="6" >
                      <div class="text-center" style="margin-left:0%; margin-right:0%;"  >
                          <v-img  :src="urls[12]">
                            <v-row no-gutters style="margin-top:117%;">
                              <v-col cols="5"></v-col>
                              <v-col cols="7">
                                <div style="margin-left:15%;" >
                                  <a target="_blank"  :href="links[5]">
                                  <v-img style="cursor:pointer; width:45%;" :src="require('@/assets/black.png')"></v-img>
                                  </a>
                                </div>
                              </v-col>
                            </v-row>
                          </v-img>
                      </div>
                  </v-col>
                  <!-- 14 but -->
                  <v-col cols="6" >
                      <div class="text-center" style="margin-left:0%; margin-right:0%;"  >
                          <v-img  :src="urls[13]">
                            <v-row no-gutters style="margin-top:120%;">
                              <v-col cols="7"></v-col>
                              <v-col cols="5">
                                <div style="margin-left:16%;">
                                  <a target="_blank"  :href="links[6]">
                                  <v-img style="cursor:pointer; width:70%;" :src="require('@/assets/black.png')"></v-img>
                                  </a>
                                </div>
                              </v-col>
                            </v-row>
                          </v-img>
                      </div>
                  </v-col>
                  <!-- 15 but -->
                  <v-col cols="6" >
                      <div class="text-center" style="margin-left:0%; margin-right:0%;"  >
                          <v-img  :src="urls[14]">
                            <v-row no-gutters style="margin-top:71%;">
                              <v-col cols="12">
                                <div style="margin-left:5%; ">
                                  <a target="_blank"  :href="links[7]">
                                  <v-img style="cursor:pointer; width:30%;" :src="require('@/assets/black.png')"></v-img>
                                  </a>
                                </div>
                              </v-col>
                            </v-row>
                          </v-img>
                      </div>
                  </v-col>
                  <!-- 16 no but -->
                  <v-col cols="6" >
                      <div class="text-center" style="margin-left:0%; margin-right:0%;"  >
                          <v-img  :src="urls[15]">
                          </v-img>
                      </div>
                  </v-col>
                  <!-- 17 but -->
                  <v-col cols="6" >
                      <div class="text-center" style="margin-left:0%; margin-right:0%;"  >
                          <v-img  :src="urls[16]">
                            <v-row no-gutters style="margin-top:10%;">
                              <v-col cols="5">
                                <div style="margin-left:5%; text-left">
                                  <a target="_blank"  :href="links[8]">
                                  <v-img style="cursor:pointer; width:70%;" :src="require('@/assets/black.png')"></v-img>
                                  </a>
                                </div>
                                <div style="margin-left:40%; margin-top:375%;">
                                  <a target="_blank"  :href="links[9]">
                                  <v-img style="cursor:pointer; width:90%;" :src="require('@/assets/black.png')"></v-img>
                                  </a>
                                </div>
                              </v-col>
                              <v-col cols="7">
                                <div style="margin-left:9%; margin-top:145%; text-left">
                                  <a target="_blank"  :href="links[10]">
                                  <v-img style="cursor:pointer; width:52%;" :src="require('@/assets/black.png')"></v-img>
                                  </a>
                                </div>
                              </v-col>
                            </v-row>
                          </v-img>
                      </div>
                  </v-col>
                  <!-- 18 but -->
                  <v-col cols="6" >
                      <div class="text-center" style="margin-left:0%; margin-right:0%;"  >
                          <v-img  :src="urls[17]">
                            <v-row no-gutters style="margin-top:160%;">
                              <v-col cols="8"></v-col>
                              <v-col cols="4">
                                <div style="margin-left:0%; margin-top:0%; text-left">
                                  <a target="_blank"  :href="links[11]">
                                  <v-img style="cursor:pointer; width:80%;" :src="require('@/assets/white.png')"></v-img>
                                  </a>
                                </div>
                              </v-col>
                            </v-row>
                          </v-img>
                      </div>
                  </v-col>
                  <!-- 19 but -->
                  <v-col cols="6" >
                      <div class="text-center" style="margin-left:0%; margin-right:0%;"  >
                          <v-img  :src="urls[18]">
                            <v-row no-gutters style="margin-top:100%;">
                              <v-col cols="12">
                                <div style="margin-left:11%; margin-top:0%; text-left">
                                  <a target="_blank"  :href="links[12]">
                                  <v-img style="cursor:pointer; width:27%;" :src="require('@/assets/white.png')"></v-img>
                                  </a>
                                </div>
                              </v-col>
                            </v-row>
                          </v-img>
                      </div>
                  </v-col>
                  <!-- 20 no but -->
                  <v-col cols="6" >
                      <div class="text-center" style="margin-left:0%; margin-right:0%;"  >
                          <v-img  :src="urls[19]">
                          </v-img>
                      </div>
                  </v-col>
                  <!-- 21 but -->
                  <v-col cols="6" >
                      <div class="text-center" style="margin-left:0%; margin-right:0%;"  >
                          <v-img  :src="urls[20]">
                            <v-row no-gutters style="margin-top:160%;">
                              <v-col cols="12">
                                <div style="margin-left:9%; margin-top:0%; text-left">
                                  <a target="_blank"  :href="links[13]">
                                  <v-img style="cursor:pointer; width:45%;" :src="require('@/assets/black.png')"></v-img>
                                  </a>
                                </div>
                              </v-col>
                            </v-row>
                          </v-img>
                      </div>
                  </v-col>
                  <!-- 22 no but -->
                  <v-col cols="6" >
                      <div class="text-center" style="margin-left:0%; margin-right:0%;"  >
                          <v-img  :src="urls[21]">
                          </v-img>
                      </div>
                  </v-col>
                  <!-- 23 but -->
                  <v-col cols="6" >
                      <div class="text-center" style="margin-left:0%; margin-right:0%;"  >
                          <v-img  :src="urls[22]">
                            <v-row no-gutters style="margin-top:60%;">
                              <v-col cols="7"></v-col>
                              <v-col cols="5">
                                <div style="margin-left:35%; margin-top:0%;" class="text-right">
                                  <a target="_blank"  :href="links[14]">
                                  <v-img style="cursor:pointer; width:80%;" :src="require('@/assets/black.png')"></v-img>
                                  </a>
                                </div>
                              </v-col>
                              <v-col cols="12">
                                <div style="margin-left:4%; margin-top:90%; text-left">
                                  <a target="_blank"  :href="links[15]">
                                  <v-img style="cursor:pointer; width:26%;" :src="require('@/assets/black.png')"></v-img>
                                  </a>
                                </div>
                              </v-col>
                            </v-row>
                          </v-img>
                      </div>
                  </v-col>
                  <!-- 24 but -->
                  <v-col cols="6" >
                      <div class="text-center" style="margin-left:0%; margin-right:0%;"  >
                          <v-img  :src="urls[23]">
                            <v-row no-gutters style="margin-top:65%;">
                              <v-col cols="6"></v-col>
                              <v-col cols="6">
                                <div style="margin-left:10%; margin-top:3%;">
                                  <a target="_blank"  :href="links[16]">
                                  <v-img style="cursor:pointer; width:66%;" :src="require('@/assets/black.png')"></v-img>
                                  </a>
                                </div>
                              </v-col>
                            </v-row>
                          </v-img>
                      </div>
                  </v-col>
                  <!-- 25 but -->
                  <v-col cols="6" >
                      <div class="text-center" style="margin-left:0%; margin-right:0%;"  >
                          <v-img  :src="urls[24]">
                            <v-row no-gutters style="margin-top:75%;">
                              <v-col cols="6"></v-col>
                              <v-col cols="5">
                                <div style="margin-left:19%; margin-top:0%; " class="text-center">
                                  <a target="_blank"  :href="links[17]">
                                  <v-img style="cursor:pointer; width:60%;" :src="require('@/assets/black.png')"></v-img>
                                  </a>
                                </div>
                              </v-col>
                            </v-row>
                          </v-img>
                      </div>
                  </v-col>
                  <!-- 26 but -->
                  <v-col cols="6" >
                      <div class="text-center" style="margin-left:0%; margin-right:0%;"  >
                          <v-img  :src="urls[25]">
                            <v-row no-gutters style="margin-top:45%;">
                              <v-col cols="8"></v-col>
                              <v-col cols="4">
                                <div style="margin-left:0%; margin-top:0%; text-left">
                                  <a target="_blank"  :href="links[18]">
                                  <v-img style="cursor:pointer; width:75%;" :src="require('@/assets/black.png')"></v-img>
                                  </a>
                                </div>
                              </v-col>
                            </v-row>
                          </v-img>
                      </div>
                  </v-col>
                  <!-- 27 but -->
                  <v-col cols="6" >
                      <div class="text-center" style="margin-left:0%; margin-right:0%;"  >
                          <v-img  :src="urls[26]">
                            <v-row no-gutters style="margin-top:55%;">
                              <v-col cols="8"></v-col>
                              <v-col cols="4">
                                <div style="margin-left:15%; margin-top:0%;" class="text-center">
                                  <a target="_blank"  :href="links[19]">
                                  <v-img style="cursor:pointer; width:80%;" :src="require('@/assets/white.png')"></v-img>
                                  </a>
                                </div>
                              </v-col>
                            </v-row>
                          </v-img>
                      </div>
                  </v-col>
                  <!-- 28 no but -->
                  <v-col cols="6" >
                      <div class="text-center" style="margin-left:0%; margin-right:0%;"  >
                          <v-img  :src="urls[27]">
                          </v-img>
                      </div>
                  </v-col>
                  <!-- 29 but -->
                  <v-col cols="6" >
                      <div class="text-center" style="margin-left:0%; margin-right:0%;"  >
                          <v-img  :src="urls[28]">
                            <v-row no-gutters style="margin-top:65%;">
                              <v-col cols="6">
                                <div style="margin-left:8%; margin-top:0%;" class="text-center">
                                  <a target="_blank"  :href="links[20]">
                                  <v-img style="cursor:pointer; width:60%;" :src="require('@/assets/black.png')"></v-img>
                                  </a>
                                </div>
                              </v-col>
                              <v-col cols="6">
                                <div style="margin-left:15%; margin-top:200%; text-left">
                                  <a target="_blank"  :href="links[21]">
                                  <v-img style="cursor:pointer; width:60%;" :src="require('@/assets/black.png')"></v-img>
                                  </a>
                                </div>
                              </v-col>
                            </v-row>
                          </v-img>
                      </div>
                  </v-col>
                  <!-- 30 no but -->
                  <v-col cols="6" >
                      <div class="text-center" style="margin-left:0%; margin-right:0%;"  >
                          <v-img  :src="urls[29]">
                          </v-img>
                      </div>
                  </v-col>
                  <!-- 31 but -->
                  <v-col cols="6" >
                      <div class="text-center" style="margin-left:0%; margin-right:0%;"  >
                          <v-img  :src="urls[30]">
                            <v-row no-gutters style="margin-top:46%;">
                              <v-col cols="8">
                                <div style="margin-left:6%; margin-top:0%; text-left">
                                  <a target="_blank"  :href="links[22]">
                                  <v-img style="cursor:pointer; width:40%;" :src="require('@/assets/white.png')"></v-img>
                                  </a>
                                </div>
                              </v-col>
                              <v-col cols="4">
                                <div style="margin-left:15%; margin-top:350%;" class="text-center">
                                  <a target="_blank"  :href="links[23]">
                                  <v-img style="cursor:pointer; width:86%;" :src="require('@/assets/white.png')"></v-img>
                                  </a>
                                </div>
                              </v-col>
                            </v-row>
                          </v-img>
                      </div>
                  </v-col>
                  <!-- 32 but -->
                  <v-col cols="6" >
                      <div class="text-center" style="margin-left:0%; margin-right:0%;"  >
                          <v-img  :src="urls[31]">
                            <v-row no-gutters style="margin-top:88%;">
                              <v-col cols="12">
                                <div style="margin-left:8%; margin-top:0%; text-left">
                                  <a target="_blank"  :href="links[24]">
                                  <v-img style="cursor:pointer; width:30%;" :src="require('@/assets/white.png')"></v-img>
                                  </a>
                                </div>
                              </v-col>
                            </v-row>
                          </v-img>
                      </div>
                  </v-col>
                  <!-- 33 but -->
                  <v-col cols="6" >
                      <div class="text-center" style="margin-left:0%; margin-right:0%;"  >
                          <v-img  :src="urls[32]">
                            <v-row no-gutters style="margin-top:47%;">
                              <v-col cols="12">
                                <div style="margin-left:10%; margin-top:0%; text-left">
                                  <a target="_blank"  :href="links[25]">
                                  <v-img style="cursor:pointer; margin-left:2%; margin-top:1%; width:25%;" :src="require('@/assets/black.png')"></v-img>
                                  </a>
                                </div>
                              </v-col>
                            </v-row>
                          </v-img>
                      </div>
                  </v-col>
                  <!-- 34 but -->
                  <v-col cols="6" >
                      <div class="text-center" style="margin-left:0%; margin-right:0%;"  >
                          <v-img  :src="urls[33]">
                            <v-row no-gutters style="margin-top:115%;">
                              <v-col cols="8"></v-col>
                              <v-col cols="4">
                                <div style="margin-left:20%; margin-top:0%; text-left">
                                  <a target="_blank"  :href="links[26]">
                                  <v-img style="cursor:pointer; width:70%;" :src="require('@/assets/black.png')"></v-img>
                                  </a>
                                </div>
                              </v-col>
                            </v-row>
                          </v-img>
                      </div>
                  </v-col>
                  <!-- 35 but -->
                  <v-col cols="6" >
                      <div class="text-center" style="margin-left:0%; margin-right:0%;"  >
                          <v-img  :src="urls[34]">
                            <v-row no-gutters style="margin-top:50%;">
                              <v-col cols="5">
                                <div style="margin-left:10%; margin-top:0%; text-left">
                                  <a target="_blank"  :href="links[27]">
                                  <v-img style="cursor:pointer; margin-left:0%; width:57%;" :src="require('@/assets/white.png')"></v-img>
                                  </a>
                                </div>
                              </v-col>
                            </v-row>
                          </v-img>
                      </div>
                  </v-col>
                  <!-- 36 but -->
                  <v-col cols="6" >
                      <div class="text-center" style="margin-left:0%; margin-right:0%;"  >
                          <v-img  :src="urls[35]">
                            <v-row no-gutters style="margin-top:10%;">
                              <v-col cols="8"></v-col>
                              <v-col cols="4">
                                <div style="margin-left:20%; margin-top:0%; text-left">
                                  <a target="_blank"  :href="links[28]">
                                  <v-img style="cursor:pointer; width:70%;" :src="require('@/assets/white.png')"></v-img>
                                  </a>
                                </div>
                              </v-col>
                            </v-row>
                          </v-img>
                      </div>
                  </v-col>
                  <!-- 37 no but -->
                  <v-col cols="6" >
                      <div class="text-center" style="margin-left:0%; margin-right:0%;"  >
                          <v-img  :src="urls[36]">
                          </v-img>
                      </div>
                  </v-col>
                  <!-- 38 but -->
                  <v-col cols="6" >
                      <div class="text-center" style="margin-left:0%; margin-right:0%;"  >
                          <v-img  :src="urls[37]">
                            <v-row no-gutters style="margin-top:65%;">
                              <v-col cols="12">
                                <div style="margin-left:5%; margin-top:0%; text-left">
                                  <a target="_blank"  :href="links[29]">
                                  <v-img style="cursor:pointer; width:30%;" :src="require('@/assets/white.png')"></v-img>
                                  </a>
                                </div>
                              </v-col>
                            </v-row>
                          </v-img>
                      </div>
                  </v-col>   
                   <!--39-40  -->
                   <v-col cols="6" v-for="(x, index) in urls.slice(38, 41)" :key="index">
                        <div class="text-center" style="margin-left:0%; margin-right:0%;"  >
                            <v-img :src="x">

                            </v-img>
                        </div>
                  </v-col>
              </v-row>
            </v-flex>
            <v-flex xs12>
            </v-flex>
        </v-layout>
    </v-main>
</template>
<script>
// import Nav from '@/components/navigation'
// import Section1 from '@/components/homepage/section1'
// import Section2 from '@/components/homepage/section2'
// import Section3 from '@/components/homepage/section3'
// import Section4 from '@/components/homepage/section4'
// import Section5 from '@/components/homepage/section5'

export default {
    components: {
        // Nav,
        // Section1,
        // Section2,
        // Section3,
        // Section4,
        // Section5
    },
    data() {
      return {
        button: [
          'https://firebasestorage.googleapis.com/v0/b/brainandbrawn-ea849.appspot.com/o/heritage%2Fwhite.png?alt=media&token=f0769dab-8efc-4084-b055-f504add6e0a4',
          'https://firebasestorage.googleapis.com/v0/b/brainandbrawn-ea849.appspot.com/o/heritage%2Fblack.png?alt=media&token=a0618a3a-e474-45bd-9653-b0f37eb4fa9f'
        ],
        links: [
          // 7
          "https://www.lotusartsdevivre.com/products/lotus-leaf-lamp",
          // 9
          // bamboo
          "https://www.lotusartsdevivre.com/products/bamboo-stool-with-gold-leaf",
          // leaves
          "https://www.lotusartsdevivre.com/products/19th-century-chinese-vase-with-japanese-temple-lotus-leaves-and-flowers",
          // 10
          // jap chair
          "https://www.lotusartsdevivre.com/products/japanese-folding-chair-kyokuroku-for-priests?variant=39252703313999",
          // rose wood table
          "https://www.lotusartsdevivre.com/products/carved-rose-wood-table-with-cabrole-legs",
          // 13 
          "https://www.lotusartsdevivre.com/products/hinoki-japanese-pine-drawer-chest",
          // 14 birdcage
          "https://www.lotusartsdevivre.com/products/wooden-bird-cage-with-two-lovebirds",
          // 15 chicken pillow
          "https://www.lotusartsdevivre.com/products/gold-cushion-with-chicken-and-hen-embriodery-turquoise-and-tassels",
          // 17 teak wood case , glass lamp, chinese table
          "https://www.lotusartsdevivre.com/products/teak-wood-vase-with-lotus-leaves-and-flowers",
          "https://www.lotusartsdevivre.com/products/chinese-chest-table",
          "https://www.lotusartsdevivre.com/products/glass-lamp-with-silver-roots?variant=39252663992399",
          // 18 Japaneses Laqquer
          "https://www.lotusartsdevivre.com/products/japanese-lacquer-low-table",
          // 19 bonsai lamp
          "https://www.lotusartsdevivre.com/products/bonsai-lamp-with-japanese-fans",
          // 21 chineses Galuchat
          "https://www.lotusartsdevivre.com/products/chinese-galuchat-cabinet",
          // 23 Red lacquer, arm chair
          "https://www.lotusartsdevivre.com/products/red-lacquer-table-with-silver-monkeys",
          "https://www.lotusartsdevivre.com/products/brushed-brass-armchair-with-liana-wood-and-silver-accents",
          // 24  MarQ Mirror
          "https://www.lotusartsdevivre.com/products/oval-mirror-with-marquetry-frames-and-scarab-frog-decor",
          // 25 wood table side
          "https://www.lotusartsdevivre.com/products/petrified-wood-side-table-with-bamboo",
          // 26 bamboo
          "https://www.lotusartsdevivre.com/products/bamboo-stool-with-gold-leaf",
          // 27 monkey
          "https://www.lotusartsdevivre.com/products/chimpanzee-table",
          // 29 bonsai, jap cabinet
          "https://www.lotusartsdevivre.com/products/bonsai-tree-with-scallop-shell",
          "https://www.lotusartsdevivre.com/products/japanese-lacquer-cabinet-with-bamboo-forest-motifs",
          // 31 rectangle 1 , rectangle 2
          "https://www.lotusartsdevivre.com/products/18th-century-victorian-mirror-with-gold-leaf-ornate-frames",
          "https://www.lotusartsdevivre.com/products/18th-century-victorian-mirror-with-gold-leaf-ornate-frames-1",
          // 32
          "https://www.lotusartsdevivre.com/products/wild-boar-table",
          // 33
          "https://www.lotusartsdevivre.com/products/19th-century-rosewood-cabinet",
          // 34
          "https://www.lotusartsdevivre.com/products/pair-of-carved-rose-wood-table-with-cabrole-legs",
          // 35
          "https://www.lotusartsdevivre.com/products/carapace-stool",
          // 36
          "https://www.lotusartsdevivre.com/products/19th-century-rose-wood-table",
          // 38
          "https://www.lotusartsdevivre.com/products/carved-grape-leaf-design-teak-table"
        ],
        urls: [
                //1- 10
                "https://firebasestorage.googleapis.com/v0/b/brainandbrawn-ea849.appspot.com/o/heritage%2FH%26B.jpg?alt=media&token=308882fc-dc94-4b32-abd3-50c59955a213",
                "https://firebasestorage.googleapis.com/v0/b/brainandbrawn-ea849.appspot.com/o/heritage%2FH%26B2.jpg?alt=media&token=3b4e7466-85c1-4f64-8c13-54f20cd3d3d1",
                "https://firebasestorage.googleapis.com/v0/b/brainandbrawn-ea849.appspot.com/o/heritage%2FH%26B3.jpg?alt=media&token=5a9ed9a4-5b54-4939-a324-3bc8414a8987",
                "https://firebasestorage.googleapis.com/v0/b/brainandbrawn-ea849.appspot.com/o/heritage%2FH%26B4.jpg?alt=media&token=50b3f615-71e2-4385-aef8-09fda088c047",
                "https://firebasestorage.googleapis.com/v0/b/brainandbrawn-ea849.appspot.com/o/heritage%2FH%26B5.jpg?alt=media&token=c2798eef-7106-43db-8d0b-3bb5e680e133",
                "https://firebasestorage.googleapis.com/v0/b/brainandbrawn-ea849.appspot.com/o/heritage%2FH%26B6.jpg?alt=media&token=4b43b1f4-5c85-4eb8-baeb-b1917311f4f8",
                "https://firebasestorage.googleapis.com/v0/b/brainandbrawn-ea849.appspot.com/o/heritage%2FH%26B7.jpg?alt=media&token=2a179035-c428-414c-b578-ccafb3172117",
                "https://firebasestorage.googleapis.com/v0/b/brainandbrawn-ea849.appspot.com/o/heritage%2FH%26B8.jpg?alt=media&token=da997ffe-002e-4c58-90d9-50d61da8d6be",
                "https://firebasestorage.googleapis.com/v0/b/brainandbrawn-ea849.appspot.com/o/heritage%2FH%26B9.jpg?alt=media&token=839a4aaf-08b2-4300-b5c2-f120d0f55445",
                "https://firebasestorage.googleapis.com/v0/b/brainandbrawn-ea849.appspot.com/o/heritage%2FH%26B10.jpg?alt=media&token=73e41b9a-5d76-4e1a-b73f-f7c37608f9fc",

                //11 - 20
                "https://firebasestorage.googleapis.com/v0/b/brainandbrawn-ea849.appspot.com/o/heritage%2FH%26B11.jpg?alt=media&token=579d00c3-4609-4fec-8aa9-d9c2e2348ad8",
                "https://firebasestorage.googleapis.com/v0/b/brainandbrawn-ea849.appspot.com/o/heritage%2FH%26B12.jpg?alt=media&token=9daacf3d-be5a-415a-8831-7819628ff752",
                "https://firebasestorage.googleapis.com/v0/b/brainandbrawn-ea849.appspot.com/o/heritage%2FP.13.jpg?alt=media&token=d553caaf-4e45-4c94-85f2-a9e91250ce77",
                "https://firebasestorage.googleapis.com/v0/b/brainandbrawn-ea849.appspot.com/o/heritage%2FH%26B14.jpg?alt=media&token=87e71cf9-00be-4da4-bbeb-0bdb63c2fc13",
                "https://firebasestorage.googleapis.com/v0/b/brainandbrawn-ea849.appspot.com/o/heritage%2FH%26B15.jpg?alt=media&token=8bf19e1b-6846-4fca-982c-4fafde366175",
                "https://firebasestorage.googleapis.com/v0/b/brainandbrawn-ea849.appspot.com/o/heritage%2FH%26B16.jpg?alt=media&token=ca7da1ed-65f6-4f67-b0f2-1561abf1c8ee",
                "https://firebasestorage.googleapis.com/v0/b/brainandbrawn-ea849.appspot.com/o/heritage%2FH%26B17.jpg?alt=media&token=3ae36a4b-dc86-4736-b6a1-8b05e88c7948",
                "https://firebasestorage.googleapis.com/v0/b/brainandbrawn-ea849.appspot.com/o/heritage%2FH%26B18.jpg?alt=media&token=28c4d767-4776-48c8-a2c9-8c6c13c4ffb8",
                "https://firebasestorage.googleapis.com/v0/b/brainandbrawn-ea849.appspot.com/o/heritage%2FH%26B19.jpg?alt=media&token=74fa4db6-e147-46dc-8bb1-890fc017a181",
                "https://firebasestorage.googleapis.com/v0/b/brainandbrawn-ea849.appspot.com/o/heritage%2FH%26B20.jpg?alt=media&token=d0e98050-9373-4fc0-bb6a-1779c2df2e12",

                // 21-30
                "https://firebasestorage.googleapis.com/v0/b/brainandbrawn-ea849.appspot.com/o/heritage%2FH%26B21.jpg?alt=media&token=aa6b096d-ff74-4a9f-964f-e496a85d444b",
                "https://firebasestorage.googleapis.com/v0/b/brainandbrawn-ea849.appspot.com/o/heritage%2FH%26B22.jpg?alt=media&token=1d2f089d-32fb-4c22-80ab-06c4e73821f2",
                "https://firebasestorage.googleapis.com/v0/b/brainandbrawn-ea849.appspot.com/o/heritage%2FH%26B23.jpg?alt=media&token=c22efd80-cd80-4808-99a9-dd98404d35f4",
                "https://firebasestorage.googleapis.com/v0/b/brainandbrawn-ea849.appspot.com/o/heritage%2Fp.24.jpg?alt=media&token=81b9f318-1d37-469e-b814-bb90aa9afc10",
                "https://firebasestorage.googleapis.com/v0/b/brainandbrawn-ea849.appspot.com/o/heritage%2FH%26B25.jpg?alt=media&token=9c9566bd-e536-4c27-8225-ba78b552f666",
                "https://firebasestorage.googleapis.com/v0/b/brainandbrawn-ea849.appspot.com/o/heritage%2FH%26B26.jpg?alt=media&token=e0aec0c7-a700-46bc-bfee-8e1be19646bb",
                "https://firebasestorage.googleapis.com/v0/b/brainandbrawn-ea849.appspot.com/o/heritage%2FH%26B27.jpg?alt=media&token=5ad536bc-0501-44b2-8666-b5d42b5a81da",
                "https://firebasestorage.googleapis.com/v0/b/brainandbrawn-ea849.appspot.com/o/heritage%2FH%26B28.jpg?alt=media&token=4888f779-9865-45b0-acf1-494cbc3141fd",
                "https://firebasestorage.googleapis.com/v0/b/brainandbrawn-ea849.appspot.com/o/heritage%2FH%26B29.jpg?alt=media&token=85804ba6-97f6-43e0-b4f0-780a3a90746b",
                "https://firebasestorage.googleapis.com/v0/b/brainandbrawn-ea849.appspot.com/o/heritage%2FH%26B30.jpg?alt=media&token=8187fba3-b3bb-49b5-a1fb-9a932aa34b03",

                //31-40

                "https://firebasestorage.googleapis.com/v0/b/brainandbrawn-ea849.appspot.com/o/heritage%2FH%26B31.jpg?alt=media&token=a6cf2981-6791-4a65-a01a-80f02d5d6343",
                "https://firebasestorage.googleapis.com/v0/b/brainandbrawn-ea849.appspot.com/o/heritage%2FH%26B32.jpg?alt=media&token=b4622fe2-816b-4ce2-92ae-c7dc9329ba39",
                "https://firebasestorage.googleapis.com/v0/b/brainandbrawn-ea849.appspot.com/o/heritage%2FH%26B33.jpg?alt=media&token=0e814d36-56ca-4056-a8a5-fabb50e3dd25",
                "https://firebasestorage.googleapis.com/v0/b/brainandbrawn-ea849.appspot.com/o/heritage%2FH%26B34.jpg?alt=media&token=b29ac506-c431-4cb7-b206-da712f55826c",
                "https://firebasestorage.googleapis.com/v0/b/brainandbrawn-ea849.appspot.com/o/heritage%2FH%26B35.jpg?alt=media&token=07c46ed3-b07b-4905-aa7f-9451dd205633",
                "https://firebasestorage.googleapis.com/v0/b/brainandbrawn-ea849.appspot.com/o/heritage%2FH%26B36.jpg?alt=media&token=c1df3d4a-64c4-4335-b6c9-ae94eaf020fa",
                "https://firebasestorage.googleapis.com/v0/b/brainandbrawn-ea849.appspot.com/o/heritage%2FH%26B37.jpg?alt=media&token=d36dd026-a952-4e67-8263-5d3d9be8c72b",
                "https://firebasestorage.googleapis.com/v0/b/brainandbrawn-ea849.appspot.com/o/heritage%2FH%26B38.jpg?alt=media&token=dbeb9813-e154-4198-9b92-ceeda03cc895",
                "https://firebasestorage.googleapis.com/v0/b/brainandbrawn-ea849.appspot.com/o/heritage%2Fp.39.jpg?alt=media&token=231a13fc-1739-44fc-bb31-c406812499af",
                "https://firebasestorage.googleapis.com/v0/b/brainandbrawn-ea849.appspot.com/o/heritage%2FH%26B40.jpg?alt=media&token=7ff61d54-8b1f-49fd-baab-96785643c0d1"

            ]
      }
    },
    
}
</script>
<style >

#myVideo {
  position: relative;
  right: 0;
  bottom: 0;
  min-width: 100%; 
  min-height: 100%;
}
.content {
  position: fixed;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  color: #f1f1f1;
  width: 100%;
  padding: 20px;
}
/* #myVideo {
  -moz-transform:scale(1.5);
  -webkit-transform:scale(1.5);
  -o-transform:scale(1.5);
  -ms-transform:scale(1.5);
  transform:scale(1.5);
  position: fixed;
  right: 0;
  bottom: 0;
  
  min-width: 100%;
  min-height: 100%;
  background-position: center top; 
} */
.content {
  position: fixed;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  color: #f1f1f1;
  width: 100%;
  padding: 20px;
}
    
</style>