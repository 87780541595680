<template>
    <v-main style="background-color:black;">
        <v-row no-gutters v-show="this.$vuetify.breakpoint.mdAndUp" style="margin-top:150px; margin-bottom:100px;">
            <v-col cols="1"></v-col>
            <v-col cols="3">
                <div class="text-left" style="color:#F3DFAD; font-size:59px;">
                    <p class="ma-0">MEET</p>
                    <p class="">THE BRAINS</p>
                    <p style="font-size:18px;">
                        adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Accumsan sit amet nulla facilisi morbi tempus. Non consectetur a erat nam at lectus urna duis. Phasellus egestas tellus rutrum tellus. Vitae ultricies leo integer malesuada. Tellus in hac habitasse platea dictumst vestibulum. Id faucibus nisl tincidunt eget nullam non. Vitae auctor eu augue ut lectus. Nibh venenatis cras sed felis eget velit. Proin sagittis nisl rhoncus mattis rhoncus urna neque viverra justo. Massa vitae tortor condimentum lacinia quis vel.
                    </p>
                </div>
            </v-col>
            <v-col cols="4">
                <v-carousel
                  hide-delimiter-background
                  :show-arrows="false"
                  class="custom"
                  style=""
                >
                  <v-carousel-item
                    v-for="(slide, i) in 4"
                    :key="i"
                  >
                    <v-sheet
                      
                      style="background-color:black;"
                    >
                      <div class="text-center" style="">
                        <v-avatar
                            size="430"
                            color="#747474"
                        >
                            <img src="@/assets/brave.jpg" alt="alt">
                        </v-avatar>
                        </div>
                    </v-sheet>
                  </v-carousel-item>
                </v-carousel>
            </v-col>
            <v-col cols="4">
                <div class="text-left" style="z-index:2; margin-top:50%;">
                    <p style="color:#F3DFAD; font-size:25px;" class="ma-0">
                        Matithorn Prachuabmoh Chaimoungkalo
                    </p>
                    <p style="color:#A90B1C; font-size:20px;">
                        Managing Director & Co-Founder
                    </p>
                </div>
            </v-col>
        </v-row>
    </v-main>
</template>
<script>
export default {
    data () {
      return {
          bgprops: {
            backgroundColor: 'black',
            backgroundImage: `url(https://firebasestorage.googleapis.com/v0/b/brainandbrawn-ea849.appspot.com/o/artwork1-01.png?alt=media&token=e8d53d30-70ad-4733-a741-ee0be4fb260e)`,
            minHeight: '100vh',
            maxHeight: '100%'
          }
      }
    }
}
</script>
<style >

#myVideo {
  position: relative;
  right: 0;
  bottom: 0;
  min-width: 100%; 
  min-height: 100%;
}
.content {
  position: fixed;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  color: #f1f1f1;
  width: 100%;
  padding: 20px;
}
/* #myVideo {
  -moz-transform:scale(1.5);
  -webkit-transform:scale(1.5);
  -o-transform:scale(1.5);
  -ms-transform:scale(1.5);
  transform:scale(1.5);
  position: fixed;
  right: 0;
  bottom: 0;
  
  min-width: 100%;
  min-height: 100%;
  background-position: center top; 
} */
.content {
  position: fixed;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  color: #f1f1f1;
  width: 100%;
  padding: 20px;
}
    
</style>