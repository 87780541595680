import Vue from 'vue'
import VueRouter from 'vue-router'
// import Home from '../views/Home.vue'
import homepage from '../views/homepage.vue'
import services from '../views/services.vue'
import meetbrains from '../views/meetbrains.vue'
import test from '../views/test.vue'
import heritage from '../views/heritage.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/index',
    name: 'homepage',
    component: homepage
  },
  {
    path: '/test',
    name: 'test',
    component: test
  },
  {
    path: '/heritage',
    name: 'heritage',
    component: heritage
  },
  {
    path: '/meetbrains',
    name: 'meetbrains',
    component: meetbrains
  },
  {
    path: '/services',
    name: 'services',
    component: services
  },
  {
    path: '/',
    name: 'Dashboard',
    component: homepage
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
