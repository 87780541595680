<template>
    <div>
        <!-- <v-layout style="margin-left:0%; margin-right:0%; background-color:black;" wrap v-show="!mobile">
            <v-flex xs6 >
                <div class="text-center" style="margin-left:0%; margin-right:0%;" v-for="(x, index) in urls1" :key="index" >
                    <v-img :src="x">

                    </v-img>
                </div>
            </v-flex>
            <v-flex xs6 >
                <div class="text-center" style="margin-left:0%; margin-right:0%;" v-for="(x, index) in urls2" :key="index" >
                    <v-img :src="x">

                    </v-img>
                </div>
            </v-flex>
        </v-layout> -->
        <v-row no-gutters style="margin-left:0%; margin-right:0%; background-color:black;" v-show="this.$vuetify.breakpoint.mdAndUp">
            <v-col cols="12" >
                <div class="text-center" style="margin-left:0%; margin-right:0%;" v-for="(x, index) in urls" :key="index" >
                    <v-img :src="x">

                    </v-img>
                </div>
            </v-col>
        </v-row>
    </div>
</template>
<script>
export default {
    async mounted () {
      if (this.$vuetify.breakpoint.smAndDown) {
          this.mobile = true
      }
    },
    data () {
        return {
            mobile: false,
            ref: [],
            urls: [
                //1- 10
                "https://firebasestorage.googleapis.com/v0/b/brainandbrawn-ea849.appspot.com/o/heritage%2FH%26B.jpg?alt=media&token=75aaaeb0-bc37-4a70-bfa1-da90e749c7be",
                "https://firebasestorage.googleapis.com/v0/b/brainandbrawn-ea849.appspot.com/o/heritage%2FH%26B2.jpg?alt=media&token=bec9acc2-dea1-4f01-9897-1ad511e2426c",
                "https://firebasestorage.googleapis.com/v0/b/brainandbrawn-ea849.appspot.com/o/heritage%2FH%26B3.jpg?alt=media&token=3180c1f5-6682-4e57-aee5-079d55560c91",
                "https://firebasestorage.googleapis.com/v0/b/brainandbrawn-ea849.appspot.com/o/heritage%2FH%26B4.jpg?alt=media&token=0aacff32-e8d9-4861-8592-1a03020e92bf",
                "https://firebasestorage.googleapis.com/v0/b/brainandbrawn-ea849.appspot.com/o/heritage%2FH%26B5.jpg?alt=media&token=14998fbd-654f-44c9-acbc-c8919b41781e",
                "https://firebasestorage.googleapis.com/v0/b/brainandbrawn-ea849.appspot.com/o/heritage%2FH%26B6.jpg?alt=media&token=7e1ac34b-83fe-4e94-a034-91546c0e9273",
                "https://firebasestorage.googleapis.com/v0/b/brainandbrawn-ea849.appspot.com/o/heritage%2FH%26B7.jpg?alt=media&token=10c2e1e5-509b-4085-8545-b29c04ed1ed5",
                "https://firebasestorage.googleapis.com/v0/b/brainandbrawn-ea849.appspot.com/o/heritage%2FH%26B8.jpg?alt=media&token=acde8511-7119-4d2e-9712-bf89007b658f",
                "https://firebasestorage.googleapis.com/v0/b/brainandbrawn-ea849.appspot.com/o/heritage%2FH%26BV9.jpg?alt=media&token=736a08bd-32a6-4c38-93a4-746f766fef96",
                "https://firebasestorage.googleapis.com/v0/b/brainandbrawn-ea849.appspot.com/o/heritage%2FH%26B10.jpg?alt=media&token=00288b17-048d-40ee-bc54-945739cb2f9d",
                //11 - 20
                "https://firebasestorage.googleapis.com/v0/b/brainandbrawn-ea849.appspot.com/o/heritage%2FH%26B11.jpg?alt=media&token=5eafd561-ce02-4f97-92c8-ae2a3dadf2b5",
                "https://firebasestorage.googleapis.com/v0/b/brainandbrawn-ea849.appspot.com/o/heritage%2FH%26B12.jpg?alt=media&token=567b5fea-5d97-49d7-a7a0-492f9dadce38",
                "https://firebasestorage.googleapis.com/v0/b/brainandbrawn-ea849.appspot.com/o/heritage%2FH%26B13.jpg?alt=media&token=da539327-b9ca-4734-b25e-282057b2fd94",
                "https://firebasestorage.googleapis.com/v0/b/brainandbrawn-ea849.appspot.com/o/heritage%2FH%26B14.jpg?alt=media&token=e990a3ae-f1aa-45c4-bd6d-d3ea63cbfd13",
                "https://firebasestorage.googleapis.com/v0/b/brainandbrawn-ea849.appspot.com/o/heritage%2FH%26B15.jpg?alt=media&token=294189c4-a523-48e6-a9e4-ca028ca77fcd",
                "https://firebasestorage.googleapis.com/v0/b/brainandbrawn-ea849.appspot.com/o/heritage%2FH%26B16.jpg?alt=media&token=71902726-7b55-4e97-aa83-4baa1fcf3aac",
                "https://firebasestorage.googleapis.com/v0/b/brainandbrawn-ea849.appspot.com/o/heritage%2FH%26B17.jpg?alt=media&token=0a39f4a6-2de9-446c-9229-fca2f54992a0",
                "https://firebasestorage.googleapis.com/v0/b/brainandbrawn-ea849.appspot.com/o/heritage%2FH%26B18.jpg?alt=media&token=c3b4a5b4-07b3-4629-9ff0-9aaadf7e5017",
                "https://firebasestorage.googleapis.com/v0/b/brainandbrawn-ea849.appspot.com/o/heritage%2FH%26B19.jpg?alt=media&token=147d6ff9-1f39-4c07-b9cf-686377403219",
                "https://firebasestorage.googleapis.com/v0/b/brainandbrawn-ea849.appspot.com/o/heritage%2FH%26B20.jpg?alt=media&token=7d61a2bc-f89e-4a1b-a0db-cdb755999f59",
                // 21-30
                "https://firebasestorage.googleapis.com/v0/b/brainandbrawn-ea849.appspot.com/o/heritage%2FH%26B21.jpg?alt=media&token=5729f08c-6118-4815-9b0a-0be8b0c52ad3",
                "https://firebasestorage.googleapis.com/v0/b/brainandbrawn-ea849.appspot.com/o/heritage%2FH%26B22.jpg?alt=media&token=99647756-f846-4831-ab29-5168f9de1b98",
                "https://firebasestorage.googleapis.com/v0/b/brainandbrawn-ea849.appspot.com/o/heritage%2FH%26B23.jpg?alt=media&token=e71ed938-129c-4a1f-990a-edc61cae8eb9",
                "https://firebasestorage.googleapis.com/v0/b/brainandbrawn-ea849.appspot.com/o/heritage%2FH%26B24.jpg?alt=media&token=4df387d9-48d2-440b-9554-f16a86788e5b",
                "https://firebasestorage.googleapis.com/v0/b/brainandbrawn-ea849.appspot.com/o/heritage%2FH%26B25.jpg?alt=media&token=97c9ba23-6962-4dfc-bb43-7a0ca4311046",
                "https://firebasestorage.googleapis.com/v0/b/brainandbrawn-ea849.appspot.com/o/heritage%2FH%26B26.jpg?alt=media&token=c39cfec6-c22b-496f-954a-9194f5ec43c5",
                "https://firebasestorage.googleapis.com/v0/b/brainandbrawn-ea849.appspot.com/o/heritage%2FH%26B27.jpg?alt=media&token=a7188560-a1a9-4e38-a613-4cff471b4731",
                "https://firebasestorage.googleapis.com/v0/b/brainandbrawn-ea849.appspot.com/o/heritage%2FH%26B28.jpg?alt=media&token=245b2767-ef6a-4aca-9013-bcfcafe50c92",
                "https://firebasestorage.googleapis.com/v0/b/brainandbrawn-ea849.appspot.com/o/heritage%2FH%26B29.jpg?alt=media&token=9a1cd60a-da90-4ae0-be4a-8f69e2dbbea6",                
                "https://firebasestorage.googleapis.com/v0/b/brainandbrawn-ea849.appspot.com/o/heritage%2FH%26B30.jpg?alt=media&token=8ebffb01-6842-49ee-a34c-6ef1f1dd97ea",
                //31-40
                "https://firebasestorage.googleapis.com/v0/b/brainandbrawn-ea849.appspot.com/o/heritage%2FH%26B31.jpg?alt=media&token=a28ecd5f-8b6a-4a73-bc4e-5408ab84b0d3",
                "https://firebasestorage.googleapis.com/v0/b/brainandbrawn-ea849.appspot.com/o/heritage%2FH%26B32.jpg?alt=media&token=e8e68361-22f7-4a93-9a57-fec89f44a5d3",
                "https://firebasestorage.googleapis.com/v0/b/brainandbrawn-ea849.appspot.com/o/heritage%2FH%26B33.jpg?alt=media&token=e3e4636c-f816-4373-96ad-f942b7455a99",
                "https://firebasestorage.googleapis.com/v0/b/brainandbrawn-ea849.appspot.com/o/heritage%2FH%26B34.jpg?alt=media&token=ae8b6300-e73a-4546-8843-64307f53f3e8",
                "https://firebasestorage.googleapis.com/v0/b/brainandbrawn-ea849.appspot.com/o/heritage%2FH%26B35.jpg?alt=media&token=fc3c40b9-a3bd-45f0-a1ac-af242ec9c50b",
                "https://firebasestorage.googleapis.com/v0/b/brainandbrawn-ea849.appspot.com/o/heritage%2FH%26B36.jpg?alt=media&token=81ddf279-8a6a-4da6-9dd2-e3136ce18054",
                "https://firebasestorage.googleapis.com/v0/b/brainandbrawn-ea849.appspot.com/o/heritage%2FH%26B37.jpg?alt=media&token=aafdeac7-dce9-4bdc-ae10-7731e9f64ec8",
                "https://firebasestorage.googleapis.com/v0/b/brainandbrawn-ea849.appspot.com/o/heritage%2FH%26B38.jpg?alt=media&token=aab775b4-06bb-4200-b8d4-4fdc50bf1270",
                "https://firebasestorage.googleapis.com/v0/b/brainandbrawn-ea849.appspot.com/o/heritage%2FH%26B39.jpg?alt=media&token=5c18f5cc-d9ef-4f9f-b732-cd5fd0be016b",
                "https://firebasestorage.googleapis.com/v0/b/brainandbrawn-ea849.appspot.com/o/heritage%2FH%26B40.jpg?alt=media&token=9887413f-c532-4381-8353-d63bc01e3672"
            ],
            urls1: [
                //1, 3, 5 ,7 ,9
                "https://firebasestorage.googleapis.com/v0/b/brainandbrawn-ea849.appspot.com/o/heritage%2FH%26B.jpg?alt=media&token=75aaaeb0-bc37-4a70-bfa1-da90e749c7be",
                "https://firebasestorage.googleapis.com/v0/b/brainandbrawn-ea849.appspot.com/o/heritage%2FH%26B3.jpg?alt=media&token=3180c1f5-6682-4e57-aee5-079d55560c91",
                "https://firebasestorage.googleapis.com/v0/b/brainandbrawn-ea849.appspot.com/o/heritage%2FH%26B5.jpg?alt=media&token=14998fbd-654f-44c9-acbc-c8919b41781e",
                "https://firebasestorage.googleapis.com/v0/b/brainandbrawn-ea849.appspot.com/o/heritage%2FH%26B7.jpg?alt=media&token=10c2e1e5-509b-4085-8545-b29c04ed1ed5",
                "https://firebasestorage.googleapis.com/v0/b/brainandbrawn-ea849.appspot.com/o/heritage%2FH%26BV9.jpg?alt=media&token=736a08bd-32a6-4c38-93a4-746f766fef96",
                // 11, 13, 15, 17, 19
                "https://firebasestorage.googleapis.com/v0/b/brainandbrawn-ea849.appspot.com/o/heritage%2FH%26B11.jpg?alt=media&token=5eafd561-ce02-4f97-92c8-ae2a3dadf2b5",
                "https://firebasestorage.googleapis.com/v0/b/brainandbrawn-ea849.appspot.com/o/heritage%2FH%26B13.jpg?alt=media&token=da539327-b9ca-4734-b25e-282057b2fd94",
                "https://firebasestorage.googleapis.com/v0/b/brainandbrawn-ea849.appspot.com/o/heritage%2FH%26B15.jpg?alt=media&token=294189c4-a523-48e6-a9e4-ca028ca77fcd",
                "https://firebasestorage.googleapis.com/v0/b/brainandbrawn-ea849.appspot.com/o/heritage%2FH%26B17.jpg?alt=media&token=0a39f4a6-2de9-446c-9229-fca2f54992a0",
                "https://firebasestorage.googleapis.com/v0/b/brainandbrawn-ea849.appspot.com/o/heritage%2FH%26B19.jpg?alt=media&token=147d6ff9-1f39-4c07-b9cf-686377403219",
                // 21 , 23, 25, 27, 29
                "https://firebasestorage.googleapis.com/v0/b/brainandbrawn-ea849.appspot.com/o/heritage%2FH%26B21.jpg?alt=media&token=5729f08c-6118-4815-9b0a-0be8b0c52ad3",
                "https://firebasestorage.googleapis.com/v0/b/brainandbrawn-ea849.appspot.com/o/heritage%2FH%26B23.jpg?alt=media&token=e71ed938-129c-4a1f-990a-edc61cae8eb9",
                "https://firebasestorage.googleapis.com/v0/b/brainandbrawn-ea849.appspot.com/o/heritage%2FH%26B25.jpg?alt=media&token=97c9ba23-6962-4dfc-bb43-7a0ca4311046",
                "https://firebasestorage.googleapis.com/v0/b/brainandbrawn-ea849.appspot.com/o/heritage%2FH%26B27.jpg?alt=media&token=a7188560-a1a9-4e38-a613-4cff471b4731",
                "https://firebasestorage.googleapis.com/v0/b/brainandbrawn-ea849.appspot.com/o/heritage%2FH%26B29.jpg?alt=media&token=9a1cd60a-da90-4ae0-be4a-8f69e2dbbea6",
                // 31, 33 , 35, 37 , 39
                "https://firebasestorage.googleapis.com/v0/b/brainandbrawn-ea849.appspot.com/o/heritage%2FH%26B31.jpg?alt=media&token=a28ecd5f-8b6a-4a73-bc4e-5408ab84b0d3",
                "https://firebasestorage.googleapis.com/v0/b/brainandbrawn-ea849.appspot.com/o/heritage%2FH%26B33.jpg?alt=media&token=e3e4636c-f816-4373-96ad-f942b7455a99",
                "https://firebasestorage.googleapis.com/v0/b/brainandbrawn-ea849.appspot.com/o/heritage%2FH%26B35.jpg?alt=media&token=fc3c40b9-a3bd-45f0-a1ac-af242ec9c50b",
                "https://firebasestorage.googleapis.com/v0/b/brainandbrawn-ea849.appspot.com/o/heritage%2FH%26B37.jpg?alt=media&token=aafdeac7-dce9-4bdc-ae10-7731e9f64ec8",
                "https://firebasestorage.googleapis.com/v0/b/brainandbrawn-ea849.appspot.com/o/heritage%2FH%26B39.jpg?alt=media&token=5c18f5cc-d9ef-4f9f-b732-cd5fd0be016b",
            ],
            urls2: [
                // 2, 4, 6, 8, 10
                "https://firebasestorage.googleapis.com/v0/b/brainandbrawn-ea849.appspot.com/o/heritage%2FH%26B2.jpg?alt=media&token=bec9acc2-dea1-4f01-9897-1ad511e2426c",
                "https://firebasestorage.googleapis.com/v0/b/brainandbrawn-ea849.appspot.com/o/heritage%2FH%26B4.jpg?alt=media&token=0aacff32-e8d9-4861-8592-1a03020e92bf",
                "https://firebasestorage.googleapis.com/v0/b/brainandbrawn-ea849.appspot.com/o/heritage%2FH%26B6.jpg?alt=media&token=7e1ac34b-83fe-4e94-a034-91546c0e9273",
                "https://firebasestorage.googleapis.com/v0/b/brainandbrawn-ea849.appspot.com/o/heritage%2FH%26B8.jpg?alt=media&token=acde8511-7119-4d2e-9712-bf89007b658f",
                "https://firebasestorage.googleapis.com/v0/b/brainandbrawn-ea849.appspot.com/o/heritage%2FH%26B10.jpg?alt=media&token=00288b17-048d-40ee-bc54-945739cb2f9d",
                // 12, 14 ,16 ,18, 20
                "https://firebasestorage.googleapis.com/v0/b/brainandbrawn-ea849.appspot.com/o/heritage%2FH%26B12.jpg?alt=media&token=567b5fea-5d97-49d7-a7a0-492f9dadce38",
                "https://firebasestorage.googleapis.com/v0/b/brainandbrawn-ea849.appspot.com/o/heritage%2FH%26B14.jpg?alt=media&token=e990a3ae-f1aa-45c4-bd6d-d3ea63cbfd13",
                "https://firebasestorage.googleapis.com/v0/b/brainandbrawn-ea849.appspot.com/o/heritage%2FH%26B16.jpg?alt=media&token=71902726-7b55-4e97-aa83-4baa1fcf3aac",
                "https://firebasestorage.googleapis.com/v0/b/brainandbrawn-ea849.appspot.com/o/heritage%2FH%26B18.jpg?alt=media&token=c3b4a5b4-07b3-4629-9ff0-9aaadf7e5017",
                "https://firebasestorage.googleapis.com/v0/b/brainandbrawn-ea849.appspot.com/o/heritage%2FH%26B20.jpg?alt=media&token=7d61a2bc-f89e-4a1b-a0db-cdb755999f59",
                // 22, 24, 26, 28, 30
                "https://firebasestorage.googleapis.com/v0/b/brainandbrawn-ea849.appspot.com/o/heritage%2FH%26B22.jpg?alt=media&token=99647756-f846-4831-ab29-5168f9de1b98",
                "https://firebasestorage.googleapis.com/v0/b/brainandbrawn-ea849.appspot.com/o/heritage%2FH%26B24.jpg?alt=media&token=4df387d9-48d2-440b-9554-f16a86788e5b",
                "https://firebasestorage.googleapis.com/v0/b/brainandbrawn-ea849.appspot.com/o/heritage%2FH%26B26.jpg?alt=media&token=c39cfec6-c22b-496f-954a-9194f5ec43c5",
                "https://firebasestorage.googleapis.com/v0/b/brainandbrawn-ea849.appspot.com/o/heritage%2FH%26B28.jpg?alt=media&token=245b2767-ef6a-4aca-9013-bcfcafe50c92",
                "https://firebasestorage.googleapis.com/v0/b/brainandbrawn-ea849.appspot.com/o/heritage%2FH%26B30.jpg?alt=media&token=8ebffb01-6842-49ee-a34c-6ef1f1dd97ea",
                // 32 , 34, 36, 38, 40
                "https://firebasestorage.googleapis.com/v0/b/brainandbrawn-ea849.appspot.com/o/heritage%2FH%26B32.jpg?alt=media&token=e8e68361-22f7-4a93-9a57-fec89f44a5d3",
                "https://firebasestorage.googleapis.com/v0/b/brainandbrawn-ea849.appspot.com/o/heritage%2FH%26B34.jpg?alt=media&token=ae8b6300-e73a-4546-8843-64307f53f3e8",
                "https://firebasestorage.googleapis.com/v0/b/brainandbrawn-ea849.appspot.com/o/heritage%2FH%26B36.jpg?alt=media&token=81ddf279-8a6a-4da6-9dd2-e3136ce18054",
                "https://firebasestorage.googleapis.com/v0/b/brainandbrawn-ea849.appspot.com/o/heritage%2FH%26B38.jpg?alt=media&token=aab775b4-06bb-4200-b8d4-4fdc50bf1270",
                "https://firebasestorage.googleapis.com/v0/b/brainandbrawn-ea849.appspot.com/o/heritage%2FH%26B40.jpg?alt=media&token=9887413f-c532-4381-8353-d63bc01e3672"
            ],
            // urls2: [
            //                     "https://firebasestorage.googleapis.com/v0/b/brainandbrawn-ea849.appspot.com/o/heritage%2Fheritage2.jpg?alt=media&token=49672621-97c2-48dd-9c28-7242624c4a9e",
            //     "https://firebasestorage.googleapis.com/v0/b/brainandbrawn-ea849.appspot.com/o/heritage%2Fheritage4.jpg?alt=media&token=4b15174f-61e7-4243-a55c-b9cc5e83bf19",
            //     "https://firebasestorage.googleapis.com/v0/b/brainandbrawn-ea849.appspot.com/o/heritage%2Fheritage6.jpg?alt=media&token=bbcefccf-80ac-4d16-854a-86568e03c8ef",
            //     "https://firebasestorage.googleapis.com/v0/b/brainandbrawn-ea849.appspot.com/o/heritage%2Fheritage8.jpg?alt=media&token=089922b4-9e94-4c24-bf51-6e1f68070094",
            //     "https://firebasestorage.googleapis.com/v0/b/brainandbrawn-ea849.appspot.com/o/heritage%2Fheritage10.jpg?alt=media&token=9c6eb9c9-3563-4c35-92b4-e80b148bae24",
            //     "https://firebasestorage.googleapis.com/v0/b/brainandbrawn-ea849.appspot.com/o/heritage%2Fheritage12.jpg?alt=media&token=e9ae65ae-1dd7-41f1-bbaf-298db3f52fa9",
            //     "https://firebasestorage.googleapis.com/v0/b/brainandbrawn-ea849.appspot.com/o/heritage%2Fheritage14.jpg?alt=media&token=c5b8f8f0-cfb1-473c-b2ec-b0b82276881e",
            //     "https://firebasestorage.googleapis.com/v0/b/brainandbrawn-ea849.appspot.com/o/heritage%2Fheritage16.jpg?alt=media&token=db1c5dab-d025-4b19-b405-3e9154ce2315",

            // ],

        }
    }
}
</script>