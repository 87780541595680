<template>
    <v-main style="background-color:black;">
        <v-layout wrap v-show="this.$vuetify.breakpoint.mdAndUp">
            <v-flex xs1></v-flex>
            <v-flex xs10 style="margin-bottom:50px;">
                <div class="text-center" style="color:#F3DFAD; padding-top:50px; font-size:59px;">
                    <p style="">COMMUNICATIONS AND ADVERTISING</p>
                </div>
            </v-flex>
            <v-flex xs1></v-flex>
            <v-flex xs12>
              <v-carousel
                  height="80vh"
                  hide-delimiters
                  :show-arrows="true"
                  class="commu"
                >
                  <v-carousel-item
                    v-for="(slide, i) in slides"
                    :key="i"
                  >
                    <v-sheet
                      :color="colors[i]"
                      height="100%"
                    >
                      <v-layout wrap>
                          <v-flex xs1></v-flex>
                          <v-flex xs3>
                              <div class="text-left" style="font-weight:400; font-size:18px; color:#F3DFAD;">
                                  <p style="font-weight:600;">LOREM BLAH BLAH</p>
                                  <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Accumsan sit amet nulla facilisi morbi tempus. Non consectetur a erat nam at lectus urna duis. Phasellus egestas tellus rutrum tellus. Vitae ultricies leo integer malesuada. Tellus in hac habitasse platea dictumst vestibulum. Id faucibus nisl tincidunt eget nullam non. Vitae auctor eu augue ut lectus. Nibh venenatis cras sed felis eget velit. Proin sagittis nisl rhoncus mattis rhoncus urna neque viverra justo. Massa vitae tortor condimentum lacinia quis vel</p>

                                  <p>
                                      Sem viverra aliquet eget sit amet tellus cras adipiscing enim. Non blandit massa enim nec. In massa tempor nec feugiat nisl pretium fusce id velit. Aliquam vestibulum morbi blandit cursus risus. Nibh ipsum consequat nisl vel pretium lectus quam id leo. Turpis massa tincidunt dui ut. Fames ac turpis egestas maecenas pharetra convallis posuere. Id diam maecenas ultricies mi eget mauris pharetra et. Semper viverra nam libero justo. Maecenas volutpat blandit aliquam etiam erat velit. Quam pellentesque nec nam aliquam. Purus in mollis nunc sed id semper risus.
                                  </p>
                              </div>
                          </v-flex>
                          <v-flex xs7>
                              <div style="margin-left:10%; border: 1px solid black; height:100%; background-color:white;">


                              </div>
                          </v-flex>
                      </v-layout>
                    </v-sheet>
                  </v-carousel-item>
                </v-carousel>
              <!-- <v-flex xs12>
                <div class="text-center" style="background-color:white;" >
                  <h1>ddd</h1>
                  <h1>ss</h1>
                </div>
              </v-flex> -->
            </v-flex>
        </v-layout>
    </v-main>
</template>
<script>
export default {
    computed: {
      swiper() {
        return this.$refs.mySwiper.$swiper
      }
    },
    components: {
    },
    directives: {
    },
    data () {
      return {
        slides: [
        'First',
        'Second',
        'Third',
        'Fourth',
        'Fifth',
        ],
        colors: [
          'black',
          'black',
          'black',
          'black',
          'black',
        ],
        model: 0,
          swiperOption: {
          // slidesPerView: 1,
          spaceBetween: 30,
          pagination: {
              el: '.swiper-pagination',
              clickable: true
            }
          },
          bgprops: {
            backgroundColor: 'black',
            backgroundImage: `url(https://firebasestorage.googleapis.com/v0/b/brainandbrawn-ea849.appspot.com/o/artwork1-01.png?alt=media&token=e8d53d30-70ad-4733-a741-ee0be4fb260e)`,
            minHeight: '100vh',
            maxHeight: '100%'
          }
      }
    }
}
</script>
<style >
.commu .mdi:before, .mdi-set {
  font-size: 100px;
  opacity: 1;
  color: #F3DFAD;
}
    
</style>
