<template>
    <v-main style="background-color:black;">
        <v-layout wrap v-show="this.$vuetify.breakpoint.mdAndUp">
            <v-flex xs1></v-flex>
            <v-flex xs10 style="margin-bottom:50px;">
                <div class="text-left" style="color:#F3DFAD; padding-top:56px; font-size:59px;">
                    <p class="ma-0">OUR</p>
                    <p class="mt-n5">BRAND PARTNERS</p>
                </div>
            </v-flex>
            <v-flex xs12>
                    <v-carousel
                      hide-delimiters
                      :show-arrows="false"
                      class="partner"
                      style=""
                      height="75vh"
                      cycle
                      interval="3000"
                    >
                      <v-carousel-item >
                        <v-sheet
                          
                          style="background-color:black;"
                        >
                          <div class="text-center" style="margin-top:4%;">
                              <img width="35%"  src="@/assets/lotus.png" alt="">
                          </div>
                        </v-sheet>
                      </v-carousel-item>
                      <v-carousel-item >
                        <v-sheet
                          
                          style="background-color:black;"
                        >
                          <div class="text-center" style="margin-top:3%;">
                              <img width="40%"  src="@/assets/syn.png" alt="">
                          </div>
                        </v-sheet>
                      </v-carousel-item>
                      <v-carousel-item >
                        <v-sheet
                          
                          style="background-color:black;"
                        >
                          <div class="text-center" style="margin-top:-3%;">
                              <img width="40%"  src="@/assets/viva.png" alt="">
                          </div>
                        </v-sheet>
                      </v-carousel-item>
                    </v-carousel>
                    
                    <div style="margin-top:50px;">
                      <img width="100%"  src="https://firebasestorage.googleapis.com/v0/b/brainandbrawn-ea849.appspot.com/o/Footer-01.png?alt=media&token=aa3763ea-65f5-4524-9269-e62637b9c858" alt="">
                    </div>
            </v-flex>
            

            
        </v-layout>
    </v-main>
</template>
<script>
export default {
    data () {
      return {
          imgs: [
            {
              src: '@/assets/lotus.png'
            },
            {
              src: '@/assets/syn.png'
            },
            {
              src: '@/assets/viva.png'
            },
            {
              src: '@/assets/viva.png'
            }
          ],
          bgprops: {
            backgroundColor: 'black',
            backgroundImage: `url(https://firebasestorage.googleapis.com/v0/b/brainandbrawn-ea849.appspot.com/o/artwork1-01.png?alt=media&token=e8d53d30-70ad-4733-a741-ee0be4fb260e)`,
            minHeight: '100vh',
            maxHeight: '100%'
          }
      }
    }
}
</script>
<style >
.partner .mdi:before, .mdi-set {
  font-size: 200px;
  opacity: 1;
  color: #F3DFAD;
}
#myVideo {
  position: relative;
  right: 0;
  bottom: 0;
  min-width: 100%; 
  min-height: 100%;
}
.content {
  position: fixed;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  color: #f1f1f1;
  width: 100%;
  padding: 20px;
}
/* #myVideo {
  -moz-transform:scale(1.5);
  -webkit-transform:scale(1.5);
  -o-transform:scale(1.5);
  -ms-transform:scale(1.5);
  transform:scale(1.5);
  position: fixed;
  right: 0;
  bottom: 0;
  
  min-width: 100%;
  min-height: 100%;
  background-position: center top; 
} */
.content {
  position: fixed;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  color: #f1f1f1;
  width: 100%;
  padding: 20px;
}
    
</style>