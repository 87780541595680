export default {
    name: "Sahaikaset Argochemical",
    menubar: {
        meet: 'MEET',
        brain: 'THE BRAINS',
        our: 'OUR',
        service: 'SERVICES',
        work: 'WORK',
        contact: 'CONTACT',
        us: 'US'
    }
}
