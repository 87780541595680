<template>
    <v-main style="background-color:black;">
        <v-layout wrap v-show="this.$vuetify.breakpoint.mdAndUp">
            <v-flex xs1></v-flex>
            <v-flex xs10 style="margin-bottom:50px;">
                <div class="text-left" style="color:#F3DFAD; padding-top:80px; font-size:59px;">
                    <p class="ma-0">SOME OF</p>
                    <p class="mt-n5">OUR WORKS</p>
                </div>
            </v-flex>
            <v-flex xs1></v-flex>
            <v-flex xs1></v-flex>
            <v-flex xs3>
                <div class="text-left" style="font-weight:400; color:#F3DFAD;">
                    <p style="font-weight:600; font-size:200%; ">LOREM BLAH BLAH</p>
                    <p style="font-size:100%">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Accumsan sit amet nulla facilisi morbi tempus. Non consectetur a erat nam at lectus urna duis. Phasellus egestas tellus rutrum tellus. Vitae ultricies leo integer malesuada. Tellus in hac habitasse platea dictumst vestibulum. Id faucibus nisl tincidunt eget nullam non. Vitae auctor eu augue ut lectus. Nibh venenatis cras sed felis eget velit. Proin sagittis nisl rhoncus mattis rhoncus urna neque viverra justo. Massa vitae tortor condimentum lacinia quis vel</p>
                </div>
            </v-flex>
            <v-flex xs8>
                <div style="position:relative; height:500px; margin-left:10%;">
                    <div class="firstt">
                    </div>
                    <div class="secondd">
                    </div>
                    <div class="thirdd">
                    </div>
                    <div class="forthh">
                    </div>
                </div>
            </v-flex>
        </v-layout>
    </v-main>
</template>
<script>
export default {
    data () {
      return {
          bgprops: {
            backgroundColor: 'black',
            backgroundImage: `url(https://firebasestorage.googleapis.com/v0/b/brainandbrawn-ea849.appspot.com/o/artwork1-01.png?alt=media&token=e8d53d30-70ad-4733-a741-ee0be4fb260e)`,
            minHeight: '100vh',
            maxHeight: '100%'
          }
      }
    }
}
</script>
<style >
.firstt , .secondd, .thirdd, .forthh {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
}

.firstt {
  cursor: pointer;
  background: var(--unnamed-color-f3dfad) 0% 0% no-repeat padding-box;
  background: #F3DFAD 0% 0% no-repeat padding-box;
  box-shadow: 8px 8px 8px #00000080;
  opacity: 1;
  height: 90%;
  width: 50%;
  animation: moveRight 1s ease-in-out;
  animation-fill-mode: forwards;  
}
@keyframes moveRight {
  0% {
    width: 50%;
    height: 90%;
    top:0%;
    left: 0%;
    z-index: 13;
    
  }
  100% {
    left: 25%;
    z-index: 9;
    width: 40%;
    height: 70%;
    top:5%;
    
  }
}


.secondd{
  cursor: pointer;
  background: var(--unnamed-color-1f6b49) 0% 0% no-repeat padding-box;
  background: #1F6B49 0% 0% no-repeat padding-box;
  box-shadow: 8px 8px 8px #00000080;
  opacity: 1;
  width: 40%;
  height: 70%;
  top:5%;
  left: 25%;
  z-index: 9;
  animation: moveleft 1s ease-in-out;
  animation-fill-mode: forwards;  
  
}
@keyframes moveleft {
  0% {
    /* top: 5%; */
    left: 25%;
    z-index: 13;
    width: 40%;
    height: 70%;
    top:5%;
  }
  100% {
    /* top:0%; */
    width: 50%;
    height: 90%;
    top:0%;
    left: 0%;
    z-index: 13;
    /* transform: translateX(-63%); */
  }
}
/* .secondd:hover{

  z-index: 13;
  animation: moveleft 5s linear;
} */
.thirdd:hover {
  /* z-index: 13; */
}
.thirdd{
  cursor: pointer;
  background: var(--unnamed-color-a90b1c) 0% 0% no-repeat padding-box;
  background: #A90B1C 0% 0% no-repeat padding-box;
  box-shadow: 8px 8px 8px #00000080;
  opacity: 1;
  top: 10%;
  left: 45%;
  cursor: pointer;
  z-index: 8;
  height: 50%;
  width: 30%;
}
.forthh:hover {
  /* z-index: 13; */
}
.forthh{
  cursor: pointer;
  background: #253C7D 0% 0% no-repeat padding-box;
  box-shadow: 8px 8px 8px #00000080;
  opacity: 1;
  width: 20%;
  height: 30%;
  top: 15%;
  left: 60%;
  z-index: 7;
}
#myVideo {
  position: relative;
  right: 0;
  bottom: 0;
  min-width: 100%; 
  min-height: 100%;
}
.content {
  position: fixed;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  color: #f1f1f1;
  width: 100%;
  padding: 20px;
}

.content {
  position: fixed;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  color: #f1f1f1;
  width: 100%;
  padding: 20px;
}
    
</style>