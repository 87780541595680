<template>
    <div style="padding:0px; margin:0px;" class="brains">

        <v-row no-gutters style="background-color:black; padding-top:1%;" v-show="this.$vuetify.breakpoint.mdAndUp">
          <v-col cols="1"></v-col>
          <v-col cols="3" style="cursor:pointer;" @click="goto('homepage')">
            <div class="text-left">
                <img style="height:140px;  width:140px;" src="@/assets/logo1.png" alt="">
            </div>
          </v-col>
          <v-col cols="5" style="">
            <v-row no-gutters>
              <v-col @click="goto('meetbrains')" cols="3" style="cursor:pointer; ">
                    <div v-show="meet == true" class="meet" style="position:relative;">
                      <div style="left:45%;  position:absolute; top:28px" class="circle2"></div>
                      <div style="left:35%; top:48px; position:absolute;" class="circle"></div>
                    </div>
                    <div  style="margin-top:25%; " class="text-left" @mouseover="hoverMeet()" @mouseleave="leaveMeet()">
                        <p class="ma-0" style="font-weight:700; color: #F3DFAD; font-size:20px; ">
                          {{lang['menubar']['meet']}}
                        </p>
                        <p class="ma-0" style="font-weight:700; color: #F3DFAD; font-size:20px; ">
                          {{lang['menubar']['brain']}}
                        </p>
                    </div>
              </v-col>
              <v-col @click="goto('services')"  cols="3" style="cursor:pointer;" @mouseover="hoverService()" @mouseleave="leaveService()">
                    <div  v-show="service == true" style="position:relative;" class="text-left">
                      <div style="left:40%;  position:absolute; top:28px" class="circle2"></div>
                      <div style="left:30%; top:48px; position:absolute;" class="circle"></div>
                    </div>
                    <div style="margin-top:25%;" class="text-left">
                        <p class="ma-0" style="font-weight:700; color: #F3DFAD; font-size:20px; ">
                          {{lang['menubar']['our']}}
                        </p>
                        <p class="ma-0" style="font-weight:700; color: #F3DFAD; font-size:20px; ">
                          {{lang['menubar']['service']}}
                        </p>
                    </div>
              </v-col>
              <v-col cols="3" style="cursor:pointer;" @mouseover="hoverWork()" @mouseleave="leaveWork()">
                    <div  v-show="work == true" style="position:relative;" >
                      <div style="left:40%;  position:absolute; top:28px" class="circle2"></div>
                      <div style="left:30%; top:48px; position:absolute;" class="circle"></div>
                    </div>
                    <div style="margin-top:25%; " class="text-left">
                        <p class="ma-0" style="font-weight:700; color: #F3DFAD; font-size:20px; ">
                          {{lang['menubar']['our']}}
                        </p>
                        <p class="ma-0" style="font-weight:700; color: #F3DFAD; font-size:20px; ">
                          {{lang['menubar']['work']}}
                        </p>
                    </div>
              </v-col>
              <v-col cols="3" style="cursor:pointer;" @mouseover="hoverContact()" @mouseleave="leaveContact()">
                    <div  v-show="contact == true" style="position:relative;">
                      <div style="left:65%;  position:absolute; top:28px" class="circle2"></div>
                      <div style="left:55%; top:48px; position:absolute;" class="circle"></div>
                    </div>
                    <div style="margin-top:25%; " class="text-left">
                        <p class="ma-0" style="font-weight:700; color: #F3DFAD; font-size:20px; ">
                          {{lang['menubar']['contact']}}
                        </p>
                        <p class="ma-0" style="font-weight:700; color: #F3DFAD; font-size:20px; ">
                          {{lang['menubar']['us']}}
                        </p>
                    </div> 
              </v-col>
            </v-row>
          </v-col>
          
          <v-col cols="2">
                <div class="text-right" style="margin-top:17%; cursor:pointer;" >
                  <p style="font-weight:700; color: #F3DFAD; font-size:20px; ">
                      TH | EN
                  </p>
                </div>   
          </v-col>
        </v-row>
        <v-navigation-drawer v-model="drawer" width="70%" fixed temporary>
            <v-divider></v-divider>
            <v-list
              dense
              nav
            >
              <v-list-item
                v-for="item in lang['menuNav']"
                :key="item.page"
                link
                @click="navigate(item.page)"
                style="margin-top:5%;"
              >
                <!-- <v-list-item-icon>
                  <v-icon x-large="">{{ item.icon }}</v-icon>
                </v-list-item-icon> -->

                <v-list-item-content>
                  <v-list-item-title style="font-size:16px; color:#828282; line-height: 32px">{{ item.name }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
            <v-divider></v-divider>
        </v-navigation-drawer>
        <v-toolbar v-show="this.$vuetify.breakpoint.smAndDown" fixed style="height:125px; border-bottom:1px solid white; width:100%; padding:0px; background-color:black;">
              
              <v-layout wrap style="margin-top:27%;">
                <v-flex xs6>
                    <div class="text-left pl-6">
                        <!-- <v-btn @click="toggle" fab text >
                            <v-icon
                                x-large
                                color="#F3DFAD"
                                style="font-size:80px;"
                            >
                                menu
                            </v-icon>
                        </v-btn> -->
                    </div>
                </v-flex>
                <v-flex xs6>
                    <div class="text-right pr-6" style="margin-top:-10%; position:relative">
                        <img style="cursor:pointer; " height="90px"   width="90px" src="@/assets/logo1.png">
                    </div>
                </v-flex>
              </v-layout>              
        </v-toolbar>
    </div>

    
</template>
<script>
import eng from '@/components/lang/eng.js'
import thai from '@/components/lang/thai.js'
export default {
  computed: {
    lang: function () {
      if (this.$vuetify.lang.current == 'en') {
        return eng
      } else {
        return thai
      }
    }
  },
  mounted () {
    console.log()
  },
  components: {
  },
  methods: {
    goto (page) {
      this.$router.push({name: page})
    },
    hoverMeet () {
      this.meet = true   
    },
    leaveMeet () {
      this.meet = false
    },
    hoverService () {
      this.service = true   
    },
    leaveService () {
      this.service = false
    },
    hoverWork () {
      this.work = true   
    },
    leaveWork () {
      this.work = false
    },
    hoverContact () {
      this.contact = true   
    },
    leaveContact () {
      this.contact = false
    }
  },
  data () {
    return {
      meet: false,
      service: false,
      work: false,
      contact: false,
      notifications: 0,
      user: '',
      loginDialog: false,
      loginDialogMobile: false,
      dialog: false,
      dialogMobile: false,
      drawer: false,
      // disease: 'Disease',
      //   home: "Home",
      //   aboutus: "About us",
      //   product: "Products",
      //   insect: "Insects",
      //   news: "News",
      //   contactus: "Contact us",
      //   workwithus: "Career",
      //   companaynetwork: "Partner",
      //   changelang: "Settings",
      //   domestic: "Domestic & Export",
      //   contactsale: "Contact Sale",
      //   more: "More"
      items: [ {title: 'หน้าแรก', icon: 'home', visibility: 'true', function: 'homepage'},
      {title: 'รีวิวรถใหม่', icon: 'directions_car', function: 'review', visibility: 'true'},
      {title: 'ข้อเสนอ', icon: 'local_offer', function: 'offer', visibility: 'true'}
      ],
      items2: [ {title: 'บัญชี', icon: 'account_circle'} ]
    }
  }
}
</script>
<style scope>
.circle{
    position: absolute;
    -webkit-border-radius:15px;
    -moz-border-radius:15px;
    border-radius:15px;
    border:1px solid #A90B1C;
    background-color: #A90B1C;
    width:15px;
    height:15px;
}
.circle2{
    position: absolute;
    -webkit-border-radius:25px;
    -moz-border-radius:25px;
    border-radius:25px;
    border:1px solid #A90B1C;
    background-color: #A90B1C;
    width:25px;
    height:25px;
}
.brains {
  font-family: 'Montserrat', sans-serif;
}
</style>